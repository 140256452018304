import React, { useState } from 'react'
import Box from '@mui/material/Box'
import PropTypes from 'prop-types';
import FilterAvailability from './components/FilterAvailability';
import FilterType from './components/FilterType';
import FilterCategory from './components/FilterCategory';
import FilterPriceRange from './components/FilterPriceRange';


 const SortNFTs = ({
    wallet,
    urlNFT,
    setTypeFilter,
    limitNFTRef,
    listNFT,
    setNewsNFTs,
    setSliceNFT,
    setNewsMyNFT,
    setSliceMyNFT,
    requestIsLoad,
    requestIsLoadOwner,
    countNfts,
    pageNftRef,
    countMyNFT,
    pageMyNFTRef,
    rangeBottom,
    setRangeBottom,
    rangeTop,
    setRangeTop,
    changeFilter,
    ownerNFTUrl,
    setOwnerNFTUrl,
    tabActive,
    setSelectedFilter,
    urlNFTString,
    categoriesString,
    setCategoriesString
    }) => {
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [isMp3, setIsMp3] = useState(false)
    const [isPdf, setIsPdf] = useState(false)
    const [allTypes, setAllTypes] = useState(false)
    const [isAuction, setIsAuction] = useState(false)
    const [isBuyNow, setIsBuyNow] = useState(false)
    const [tempRangeBottom, setTempRangeBottom] = useState(-1)
    const [tempRangeTop, setTempRangeTop] = useState(-1)
    

    const removeFilter = () =>{
        resetFilter('type');
        setTypeFilter('')
        setSelectedFilter('')
        if(tabActive.current === "nft"){
            countNfts.current = listNFT;
            pageNftRef.current = 0;
            setSliceNFT(3)
            setNewsNFTs([])
            urlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&key_name=OWNER&key_val=${wallet}`)
            return 0;
        }
        if(tabActive.current === "myNFt"){
            countMyNFT.current = listNFT;
            pageMyNFTRef.current = 0;
            setSliceMyNFT(3)
            setNewsMyNFT([])
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&wallet=${wallet}`)
            return 0;
        }
    }

    const resetFilter = (filter) =>{
        if(filter === "priceRange"){
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }
        if(filter == "availability"){
            setRangeBottom(0)
            setRangeTop(0)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setCategoriesString('')
            return ;
        }

        if(filter === "type"){
            setRangeBottom(0)
            setRangeTop(0)
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }

        if(filter === "currency"){
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
            setIsAuction(false)
            setIsBuyNow(false)
            setCategoriesString('')
            return;
        }

        if(filter === "category"){
            setRangeBottom(0)
            setRangeTop(0)
            setIsAuction(false)
            setIsBuyNow(false)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
    }

    const handleAllNFTs = (check) =>{
        changeFilter.current = true;
        setSelectedFilter('SEARCH')
        setIsImage(false)
        setIsVideo(false)
        setIsMp3(false)
        setIsPdf(false)
        setAllTypes(check)
        if(check){
            if(tabActive.current === "nft"){
                countNfts.current = listNFT;
                pageNftRef.current = 0;
                setSliceNFT(3)
                setNewsNFTs([])
                urlNFT(`${process.env.REACT_APP_URL_API}/nft?key_name=OWNER&key_val=${wallet}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0`)
                return 0;
            }
            if(tabActive.current === "myNFt"){
                countMyNFT.current = listNFT;
                pageMyNFTRef.current = 0;
                setSliceMyNFT(3)
                setNewsMyNFT([])
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?wallet=${wallet}&domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created`)
                return 0;
            }
        }else{
            removeFilter()
        }
    }
   

    const handleClickType= (check,value) => {
        resetFilter("type");
        setTypeFilter("type");
        if(value == 'ONLY_VIDEO'){
            changeFilter.current = true;
            setIsImage(false)
            setIsVideo(check)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
        if(value == 'ONLY_IMAGE'){
            changeFilter.current = true;
            setSelectedFilter('ONLY_IMAGE')
            setIsImage(check)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
            setIsPdf(false)
        }
        if(value == 'ONLY_PDF'){
            changeFilter.current = true;
            setSelectedFilter('ONLY_PDF')
            setIsPdf(check)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsMp3(false)
        }
        if(value == 'ONLY_MP3'){
            changeFilter.current = true;
            setSelectedFilter('ONLY_MP3')
            setIsMp3(check)
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(false)
            setIsPdf(false)
        }
        if(value == 'SEARCH'){
            handleAllNFTs(check)
            return 0;
        }
        if(check){
            if(tabActive.current === "nft"){
                countNfts.current = listNFT;
                pageNftRef.current = 0;
                setSliceNFT(3)
                setNewsNFTs([])
                urlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=${value}&key_val=1&key_name=OWNER&key_val=${wallet}`)
                return 0;
            }
            if(tabActive.current === "myNFt"){
                countMyNFT.current = listNFT;
                pageMyNFTRef.current = 0;
                setSliceMyNFT(3)
                setNewsMyNFT([])
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&wallet=${wallet}`)
                return 0;
            }
        }else{
            removeFilter()
        }
    }


    const handleClickStatus= (check,value) => {
        resetFilter("availability")
        setTypeFilter("availability");
        if(value == 'BUY_NOW'){
            changeFilter.current = true;
            setIsAuction(false)
            setIsBuyNow(check)
            setSelectedFilter('BUY_NOW')
        }
        if(value == 'LIVE_AUCTION'){
            changeFilter.current = true;
            setIsAuction(check)
            setIsBuyNow(false)
            setSelectedFilter('LIVE_AUCTION')
        }
        if(check){
            if(tabActive.current === "nft"){
                countNfts.current = listNFT;
                pageNftRef.current = 0;
                setSliceNFT(3)
                setNewsNFTs([])
                urlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=${value}&key_val=1&key_name=OWNER&key_val=${wallet}`)
                return 0;
            }
            if(tabActive.current === "myNFt"){
                countMyNFT.current = listNFT;
                pageMyNFTRef.current = 0;
                setSliceMyNFT(3)
                setNewsMyNFT([])
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&wallet=${wallet}`)
                return 0;
            }
        }else{
            removeFilter()
        }
    }


    const handleClickSetPriceRange = async() => {
        if(tempRangeBottom == rangeBottom || tempRangeTop == rangeTop){return 0}
        resetFilter("priceRange");
        setTypeFilter("priceRange");
        changeFilter.current = true;
        if(tabActive.current === "nft"){
            countNfts.current = listNFT;
            pageNftRef.current = 0;
            setSliceNFT(3)
            setNewsNFTs([])
            urlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limitNFTRef.current}&page=0&order=created&key_name=PRICE_RANGE_BOTTOM&key_val=${rangeBottom}&key_name=PRICE_RANGE_TOP&key_val=${rangeTop}&domain=${process.env.REACT_APP_DOMAIN}&key_name=OWNER&key_val=${wallet}`)
            return 0;
        }
        if(tabActive.current === "myNFt"){
            countMyNFT.current = listNFT;
                pageMyNFTRef.current = 0;
                setSliceMyNFT(3)
                setNewsMyNFT([])
            setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?limit=${limitNFTRef.current}&page=0&order=created&key_name=PRICE_RANGE_BOTTOM&key_val=${rangeBottom}&key_name=PRICE_RANGE_TOP&key_val=${rangeTop}&domain=${process.env.REACT_APP_DOMAIN}&wallet=${wallet}`)
            return 0;
        }
        setTempRangeBottom(rangeBottom);
        setTempRangeTop(rangeTop);
    }

    const handleChangeCategories = (value) => {
        resetFilter("category");
        setTypeFilter("category");
        if(tabActive.current === "nft"){
            countNfts.current = listNFT;
            pageNftRef.current = 0;
            setSliceNFT(3)
            setNewsNFTs([])
            let url = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&key_name=OWNER&key_val=${wallet}`;
            if(url == urlNFTString){
                urlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&key_name=OWNER&key_val=${wallet}&domain=${process.env.REACT_APP_DOMAIN}`)
            }else{
                urlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&key_name=OWNER&key_val=${wallet}`)
            }
            return 0;
        }
        if(tabActive.current === "myNFt"){
            countMyNFT.current = listNFT;
            pageMyNFTRef.current = 0;
            setSliceMyNFT(3)
            setNewsMyNFT([])
            let url =`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&wallet=${wallet}`;
            if(url == ownerNFTUrl){
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&wallet=${wallet}&domain=${process.env.REACT_APP_DOMAIN}`)
            }else{
                setOwnerNFTUrl(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limitNFTRef.current}&page=0&order=created&key_name=CATEGORY&key_val=${value}&wallet=${wallet}`)
            }
            return 0;
        }
    }

    const handleChangeRangeBottom = (e) =>{
        const {value} = e.target;
        if(Number(value) < 0){
            setRangeBottom(0)
        }else{
            setRangeBottom(Number(value))
        }       
    }

    const handleChangeRangeTop = (e) =>{
        const {value} = e.target;
        if(Number(value) < 0){
            setRangeTop(0)
        }else{
            setRangeTop(Number(value))
        }  
    }


    return (
        <Box 
            sx={{
                position:'sticky',
                width:{xs:'100%',sm:'320px'},
                top: 0,
                '@media screen and (max-width: 768px)':{
                    position:'block',
                    width:'100%'
                }
            }}
        >
            <FilterPriceRange 
                requestIsLoad={requestIsLoad}
                requestIsLoadOwner={requestIsLoadOwner}
                rangeBottom={rangeBottom}
                rangeTop={rangeTop}
                handleClickSetPriceRange={handleClickSetPriceRange}
                handleChangeRangeBottom={handleChangeRangeBottom}
                handleChangeRangeTop={handleChangeRangeTop}
            />

            <FilterAvailability
                requestIsLoad={requestIsLoad}
                requestIsLoadOwner={requestIsLoadOwner}
                handleClickStatus={handleClickStatus}
                isBuyNow={isBuyNow}
                setIsBuyNow={setIsBuyNow}
                isAuction={isAuction}
                setIsAuction={setIsAuction}
            />
        
            <FilterType 
                requestIsLoad={requestIsLoad}
                requestIsLoadOwner={requestIsLoadOwner}
                handleClickType={handleClickType}
                isImage={isImage}
                isVideo={isVideo}
                isMp3={isMp3}
                isPdf={isPdf}
                allTypes={allTypes}
            />
            
            <FilterCategory 
                requestIsLoad={requestIsLoad}
                requestIsLoadOwner={requestIsLoadOwner}
                categoriesString={categoriesString}
                setCategoriesString={setCategoriesString}
                handleChangeCategories={handleChangeCategories}
            />
        </Box>
    )
}
SortNFTs.defaultProps = {
    wallet:  '',
    urlNFT: ()=>{},
    setUrlMyNFT: ()=>{},
    typeFilter: '',
    setTypeFilter: ()=>{},
    loadingNFT: false,
    limitNFTRef: {current:10},
    listNFT: 10,
    setNewsNFTs: ()=>{},
    setSliceNFT: ()=>{},
    setNewsMyNFT: ()=>{},
    setSliceMyNFT: ()=>{},
    requestIsLoad: false,
    requestIsLoadOwner: false,
    countNfts: {current:10},
    pageNftRef:{current:0},
    countMyNFT:{current:10},
    pageMyNFTRef:{current:0},
    rangeBottom:0,
    setRangeBottom: ()=>{},
    rangeTop:0,
    setRangeTop: ()=>{},
    changeFilter: {current:false},
    ownerNFTUrl:'',
    setOwnerNFTUrl: ()=>{},
    tabActive: {current:'nft'},
    setSelectedFilter: ()=>{},
    urlNFTString:'',
    categoriesString:'',
    setCategoriesString: ()=>{}
}

SortNFTs.propTypes = {
    wallet: PropTypes.string,
    urlNFT: PropTypes.func,
    setUrlMyNFT: PropTypes.func,
    typeFilter:PropTypes.string,
    setTypeFilter: PropTypes.func,
    loadingNFT: PropTypes.bool,
    limitNFTRef: PropTypes.object,
    listNFT: PropTypes.number,
    setNewsNFTs: PropTypes.func,
    setSliceNFT: PropTypes.func,
    setNewsMyNFT: PropTypes.func,
    setSliceMyNFT: PropTypes.func,
    requestIsLoad: PropTypes.bool,
    requestIsLoadOwner: PropTypes.bool,
    countNfts: PropTypes.object,
    pageNftRef:PropTypes.object,
    countMyNFT:PropTypes.object,
    pageMyNFTRef:PropTypes.object,
    rangeBottom: PropTypes.number,
    setRangeBottom:PropTypes.func,
    rangeTop: PropTypes.number,
    setRangeTop: PropTypes.func,
    changeFilter: PropTypes.object,
    ownerNFTUrl : PropTypes.string,
    setOwnerNFTUrl: PropTypes.func,
    tabActive: PropTypes.object,
    setSelectedFilter: PropTypes.func,
    urlNFTString: PropTypes.string,
    categoriesString: PropTypes.string,
    setCategoriesString: PropTypes.func
}
export default SortNFTs
