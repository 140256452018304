import React, { Fragment } from 'react';
import ProfileCard from '../../components/ProfileCard';
import { useFetch } from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import LoaderCircle from 'components/LoaderCircle';
import { Box, Typography } from '@mui/material';
import ErrorMessage from 'components/ErrorMessage';
import PropTypes from 'prop-types';

const RoleProfiles = ({ address, role }) => {
    const { t } = useTranslation("translate");
    const curatorsRole = 5;
    const artistssRole = 2;
    const baseURL = `${process.env.REACT_APP_URL_API}/user?domain=${process.env.REACT_APP_DOMAIN}&wallet=${address}`
    const url = () => {
        if (role == artistssRole) return `${baseURL}&role=${artistssRole}`;
        if (role == curatorsRole) return `${baseURL}&role=${curatorsRole}`;
    }
    const errorMessage = () => {
        if (role == artistssRole) return t("profile.error_artist");
        if (role == curatorsRole) return t("profile.error_curators");
    }

    const { data: profile, loading: loadingProfile, error: errorProfile } = useFetch(url());
    if(loadingProfile) {
        return (
            <Box width="100%" sx={{margin: '0 auto'}}>
                <LoaderCircle text={t("profile.loading_profiles")} />
            </Box>
        );
    }
    if(errorProfile && !loadingProfile){
        return <ErrorMessage error={errorMessage()} />;
    }
    return (
        <Fragment>
            {profile && !loadingProfile && <ProfileCard content={profile} />}
            {profile && profile.length == 0 && !loadingProfile && 
                <Box
                sx={{width:'100%', height:'100%', display:'flex', justifyContent:'center', alignItems:'center',minHeight: '200px'}}
                >
                    <Typography variant='h4' sx={{color:'#A658D8',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>
                        {t("explore.not_found")}
                    </Typography>
                </Box>
            }
        </Fragment>
    );
};

RoleProfiles.propTypes = {
    address: PropTypes.string,
    role: PropTypes.number
}

export default RoleProfiles;