import Web3 from 'web3'


export const sign = async(text,from,provider) => {
    let web3 = new Web3(provider);
    let message = web3.utils.sha3(text+" "+new Date().getTime())
    console.log('message ::',message);
    let hex = ''
    for(let i=0;i<message.length;i++) {
        hex += ''+message.charCodeAt(i).toString(16)
    }
    const hexMessage  = "0x" + hex
    let signature = null;
    signature = await web3.eth.personal.sign(hexMessage, from)
    return { signature, message }
}
