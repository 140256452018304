import axios from "axios";

export const getFollowers = async(wallet) => {
    return axios.get(process.env.REACT_APP_URL_API+`/follow?domain=${process.env.REACT_APP_DOMAIN}&wallet=${wallet}`)
    .then(res=>res.data)
}

export const getFollowing = async(wallet) => {
    return axios.get(process.env.REACT_APP_URL_API+`/follow?domain=${process.env.REACT_APP_DOMAIN}&follower=${wallet}`)
    .then(res=>res.data)
}