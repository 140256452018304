import React, { useContext, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import {  Box, AppBar,InputBase  } from '@mui/material';
import {CustomNavLink } from './styles/styles';
import DesktopBar from './components/DesktopBar/DesktopBar';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@mui/icons-material/Menu';
import MobileBar from './components/MobileBar';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import GridOnIcon from '@mui/icons-material/GridOn';
import FeedIcon from '@mui/icons-material/Feed';
import GroupsIcon from '@mui/icons-material/Groups';
import PresentationBackground from 'assets/background/newBackground.png'
import MenuMobileBackground from 'assets/background/MenuMobile.png'
import { topBar } from 'api/sanity';
import LinkIcon from '@mui/icons-material/Link';

const TopBar = () => {
    const { t } = useTranslation("translate");
    const { setOpenNav } = useContext(DrawerMobileContext);
    const history = useNavigate();
    const [search, setSearch] = useState('');
    const [focusSearch, setFocusSearch] = useState(false);
    const [items,setItems] = React.useState([]);


    const handleSearch = (route) =>{
        history(`/explore?SLUG=${route}`)
    }
    const handleOpenNav = () => {
        setOpenNav(true);
    }
    
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value)
    }

    const validationIsPublicLink = (item) =>{
        let valid = false;
        if(item && !item.externalLink && !item.internalLink || item.externalLink && item.internalLink){
            return false;
        }else{
            if(item && item.externalLink){
                valid = true;
            }
        }
        return valid;
    }

    const validationTitleOrICon = (text,getIcon) =>{
        if(String(text).toUpperCase().includes(String(t('topbar.explore'))) || String(text).toUpperCase().includes("EXPLORE")){
            return !getIcon? t('topbar.explore'):GridOnIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.curators'))) || String(text).toUpperCase().includes("CURATORS")){
            return !getIcon ? t('topbar.curators'):GroupsIcon
        }
        if(String(text).toUpperCase().includes(String(t('topbar.about'))) || String(text).toUpperCase().includes("ABOUT")){
            return !getIcon ? t('topbar.about'):FeedIcon
        }
        return !getIcon ? text:LinkIcon;
    }


    React.useEffect(() => {
        topBar().then(response=>{
            try{
                if(response[0] && response[0].menu){
                    let newArray = new Array();
                    response[0].menu?.map((item)=>{
                        newArray.push({
                            name: validationTitleOrICon(item.title,false),
                            icon: validationTitleOrICon(item.title,true),
                            path: item.link,
                            visible : true,
                            public: validationIsPublicLink(item)
                        })
                    })
                    setItems(newArray)
                }
            }catch (e) {
                console.error(e)
            }
        });
    },[]);



    return (
        <Box
            sx={{
                width:'100%',
                height:{xs:'60px',sm:'62px',md:'90px'},
                display:'flex',
                alignItems:'flex-end',
                justifyContent:'center',
                backgroundImage:{xs:`url(${MenuMobileBackground})`,sm:`url(${PresentationBackground})`},
                backgroundSize: 'cover',
                backgroundPosition: 'cover',
                backgroundRepeat:'no-repeat',
                //background:'linear-gradient(90deg, rgba(55, 30, 217, 1) 0%, rgba(62, 12, 242, 1) 3%, rgba(243, 242, 242, 1) 49%, rgba(242, 242, 242, 1) 51%, rgba(166, 89, 217, 1) 94%, rgba(166, 48, 217, 1) 100%)',
                top:'0',
                left:'0',
                position:'fixed',
                zIndex:'1100 !important'                
            }}
        >
            <Box
                sx={{
                    width:'100%',
                    height:{xs:'60px',sm:'60px',md:'90px'},
                    display:'flex',
                    alignItems:'flex-end',
                    justifyContent:'center',
                    top:'0',
                    left:'0',
                    position:'fixed',
                    zIndex:'1100 !important'  
                }}
            >   
                <Box
                    sx={{
                        width:'86%',
                        margin:'0 auto',
                        "@media screen and (max-width: 1300px)":{
                            width:'calc(100vw - 2%)',
                        },
                        "@media screen and (max-width: 758px)": {
                            width:'100%',
                            minWidth:'100%',
                            maxWidth:'100%',
                            mt:'10px'
                        }
                    }}
                >
                <AppBar position="static" sx={{backgroundColor: 'transparent',boxShadow:'none',border:'none',p:'0px 0px',transition:'none'}}>

                    <Toolbar sx={{height:'auto',p:'0px 0px'}}>
                        <Box
                            sx={{
                                color:'#fff',
                                fontFamily:'Futura',
                                "@media screen and (max-width: 1300px)":{
                                    mr:'0px',
                                    ml:'0px',
                                },
                                "@media screen and (max-width: 758px)": {
                                    mr:'0px',
                                    ml:'0px',
                                }
                            }}
                        > 
                            <CustomNavLink  to="/home"    onFocus={()=>setSearch('')}>
                                <Box
                                    className="notranslate"
                                    sx={{
                                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                        fontSize:{xs:'22px',sm:'28px'},
                                        "&:hover":{
                                            color:'#fff'
                                        },
                                        "@media screen and (max-width: 758px)": {
                                            display:focusSearch ? 'none' : 'auto' ,
                                            //fontSize:'30px',
                                            ml:'5px'
                                        },
                                        "@media screen and (max-width: 199px)": {
                                            display:'none'
                                        }
                                    }}
                                >
                                    Artcrypted
                                </Box> 
                                <Box
                                    className="notranslate"
                                    sx={{   
                                        display:focusSearch ? 'none' : 'auto' ,
                                        fontSize:'30px',
                                        ml:'9px',
                                        "@media screen and (min-width: 200px)": {
                                            display:'none',
                                         
                                        }
                                    }}
                                >
                                    ArtC.
                                </Box>
                            </CustomNavLink>
                        </Box>
                        <Box
                            sx={{
                                ml:'15px',
                                "@media screen and (max-width: 758px)": {
                                    width:focusSearch ? '100%':'30%',
                                    display:'flex',
                                    alignItems:'center',
                                    transition:'all 0.3s ease-in-out',
                                    transitionDelay:'0.3s',
                                    ml:'10px'
                                },
                            
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    border:'1px solid rgba(0,0,0,0.1)',
                                    borderRadius:'10px 10px',
                                    background:'rgba(255,255,255,0.4)'
                                }}
                                onFocus={()=>setFocusSearch(true)}
                                onBlur={()=>setFocusSearch(false)}
                            >   
                                <Box
                                    onFocus={()=>setFocusSearch(true)}
                                >
                                    <InputBase 
                                        type="search"
                                        sx={{
                                            width:'100%',
                                            m:'0px 0px',
                                            p:'0px 8px',
                                            color: '#000',
                                        
                                        }}
                                        variant="standard"
                                        placeholder={"Search nfts..."}
                                        value={search}
                                        onChange={(e) => handleChangeSearch(e)}
                                        onKeyUp={(e)=>{
                                            const {key} = e;
                                            if(key == "Enter" || key == "enter"){
                                                handleSearch(search)
                                            }
                                        }}
                                    />
                                </Box>

                                <SearchIcon 
                                    onClick={()=>{handleSearch(search);}} 
                                    sx={{width:'auto',cursor:'pointer',color:'#000',mt:'3px',fontSize:'30px'}}
                                />
                            </Box>
                            </Box>
                            <Box sx={{ flexGrow: 1 }} />
                            <Box sx={{ display: { sm: 'block', md: 'none' },mr:'5px' }}>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={handleOpenNav}
                                >
                                    <MenuIcon
                                        //htmlColor="#ed2891"
                                        htmlColor="#fff"
                                        sx={{
                                            fontSize: '30px',
                                            //color: '#EBECF0',
                                        }} 
                                    />
                                </IconButton>
                            </Box>
                        <DesktopBar tabs={items} />
                        <MobileBar tabs={items} />
                    </Toolbar>
                </AppBar>
                </Box>
            </Box>
        </Box>
    );
}

export default TopBar;
