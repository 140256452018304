import React from 'react'
import { Box, CardMedia, Container } from '@mui/material';
import PropTypes from 'prop-types';

const Banner =  ({content,isVideo, randomThumb,children}) => { 
    console.log('Necesitamos visualizar video',isVideo)
    if(isVideo){
        return (
            <Box>
                <Box>
                    <CardMedia
                        component="video"
                        src={content}
                        autoPlay
                        loop
                        muted
                        sx={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                            width: '100%',
                            height: '720px',
                            position:'absolute',
                        }}
                    />
                    <Container 
                        maxWidth='sm' 
                        sx={{
                            position:'relative', 
                            height:'720px',
                            padding:'5rem',
                            marginLeft:'5rem',
                            display:'inline-flex',
                            flexDirection:'column',
                            alignItems:'flex-start',
                            '@media screen and (max-width: 600px)': {
                                marginLeft:'0',
                                padding:'0',
                                alignItems:'center',
                            }
                        }}
                    >
                        {children}
                    </Container>
                </Box>
            </Box>
        )
    }
    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            justifyContent="center"
            sx={{
                gap:'1rem',
                //backgroundImage:`url(https://f8n-production.imgix.net/collections/reuben-cover.jpg?q=75&w=1600&auto=format%2Ccompress&fit=fill)`,
                backgroundImage:`url(${content})`,
                backgroundSize:'cover',
                backgroundPosition:'center',
                backgroundRepeat:'no-repeat',
                width:'100%',
                height:'760px',
                boxSizing:'border-box',
                paddingLeft:'10rem',
                '@media screen and (max-width: 768px)':{
                    paddingLeft:'0px',
                    alignItems:'center',
                }
            }}
        >
            <Box
                sx={{
                    boxSizing:'border-box',
                    padding: '10px',
                    borderRadius:'8px',
                    marginTop:'2rem',
                    backdropFilter: 'blur(10px)',
                    backgroundColor:'rgba(255, 255, 255, 0.2)',
                    '@media screen and (max-width: 768px)':{
                        marginTop:'0px',
                    }
                }}
            >
                <CardMedia  
                    component={'img'}
                    alt='collection'
                    src={randomThumb}
                    sx={{
                        width:'160px',
                        height:'160px',
                        borderRadius:'8px',
                    }}
                />
            </Box>
            {children}
        </Box>
    )
}

Banner.propTypes = {
    content: PropTypes.string.isRequired,
    isVideo: PropTypes.bool.isRequired,
    randomThumb: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}

export default Banner