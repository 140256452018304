import React from 'react'
import { Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { RiShareBoxFill } from 'react-icons/ri';
import PropTypes from 'prop-types'

const Information = ({content}) => {
    return (
        <>
            <Box
                display='inline-flex'
                alignItems='center'
                justifyContent='space-between'
                onClick={()=>window.open(`${process.env.REACT_APP_SCAN}address/${String(content.project_key+'').toLowerCase()}`, '_blank')}
                sx={{
                    cursor: 'pointer',
                    marginTop:'1rem',
                    gap:'0.5rem',
                    backgroundColor:'rgba(0, 0, 0, 0.2)',
                    backdropFilter: 'blur(10px)',
                    boxSizing:'border-box',
                    padding:'0.5rem 1rem',
                    borderRadius: '999px',
                    textAlign:'center',
                    color:'#fff',
                    fontSize:'18px',
                    letterSpacing:'1px',
                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '
                }} 
            >
                <span>
                    {(content.project_key).substring(0,5)+ '...' +(content.project_key).substring(38,54)}
                </span>
                <span><RiShareBoxFill color='#666' /></span>
            </Box>
            <Box
                component='h2'
                sx={{
                    fontWeight:600,
                    fontSize:'76px',
                    textAlign:'center',
                    letterSpacing:'0.025em',
                    color:'#fff',
                    marginTop:'0px',
                    marginBottom:'0px',
                    backgroundColor:'rgba(0, 0, 0, 0.2)',
                    borderRadius: '30px',
                    p:'0.5rem',
                    '@media screen and (max-width: 768px)':{
                        fontSize:'40px',
                    }   
                }} 
            >
                {content.name}
            </Box>
            <Link style={{textDecoration:'none'}} to={`/profile?address=${content.user.main_key}`}>
                <Box
                    display='flex'
                    alignItems='center'
                    sx={{
                        gap:'0.5rem',
                        backgroundColor:'rgba(0, 0, 0, 0.2)',
                        backdropFilter: 'blur(10px)',
                        boxSizing:'border-box',
                        padding:'0.5rem 1rem',
                        borderRadius: '999px',
                        textAlign:'center',
                        color:'#fff',
                        fontSize:'18px',
                        letterSpacing:'1px',
                    }} 
                >
                    <span>
                        <Avatar alt="banner" src={content.user.profile_pic_url} />
                    </span>
                    {content.user.username}
                </Box>
            </Link>
        </>
    )
}

Information.propTypes = {
    content: PropTypes.object.isRequired,
}

export default Information