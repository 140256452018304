import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Collapse from  '@mui/material/Collapse';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Checkbox from  '@mui/material/Checkbox';

const styleBox  = {
    gap:'1rem',
    marginBottom:'0.5rem',
    border: '1px solid #e0e0e0',
    borderRadius:'8px',
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 1rem',
    '&:hover':{
        border: '1px solid #000',
    }
}


const IconOpenCloseFilter = ({check}) =>{
    return (
        <React.Fragment>
            {!check ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}
        </React.Fragment>
    )
}

IconOpenCloseFilter.propTypes = {
    check: PropTypes.bool
}

const FilterType = ({requestIsLoad,handleClickType,isImage,isVideo,isMp3,isPdf,allTypes}) => {
    const { t } = useTranslation("translate");
    const [type, setType] = useState(false)

    return (
        <Box
                component='article'
                display='flex'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'  
                >
                    <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.type")}</h1>
                    <span onClick={()=>setType(!type)}><IconOpenCloseFilter check={type} /></span>
                </Box>
                <Collapse in={type} timeout="auto" unmountOnExit>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                        style={{
                            backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                        }}
                        onClick={()=>{ !requestIsLoad && handleClickType(!isImage,'IMAGE')}}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isImage}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.image")}</h1>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                        style={{
                            backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                        }}
                        onClick={()=>{ !requestIsLoad && handleClickType(!isVideo,'VIDEO')}}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isVideo}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.video")}</h1>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                        style={{
                            backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                        }}
                        onClick={()=>{ !requestIsLoad && handleClickType(!isPdf,'DOCUMENT')}}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isPdf}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>PDF</h1>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                        style={{
                            backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                        }}
                        onClick={()=>{ !requestIsLoad && handleClickType(!isMp3,'TRACK')}}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isMp3}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>MP3</h1>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                        style={{
                            backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                        }}
                        onClick={()=>{ !requestIsLoad && handleClickType(!allTypes,'SEARCH')}}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={allTypes}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.all")}</h1>
                        </Box>
                    </Box>
                </Collapse>
            </Box>
    );
};

FilterType.propTypes = {
    requestIsLoad: PropTypes.bool,
    handleClickType: PropTypes.func,
    isImage: PropTypes.bool,
    isVideo: PropTypes.bool,
    isMp3: PropTypes.bool,
    isPdf: PropTypes.bool,
    allTypes: PropTypes.bool
};

export default FilterType;