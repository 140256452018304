import axios from "axios";

export const deleteFollower = async(wallet, follower) => {
    let url = process.env.REACT_APP_URL_API+`/follow?domain=${process.env.REACT_APP_DOMAIN}&wallet=${wallet}&follower=${follower}`
    let data = {
        domain:process.env.REACT_APP_DOMAIN,
        wallet:wallet,
        follower:follower
    }
    return axios.delete(url,data,{
        headers:{
            'Content-Type': 'text/plain;charset=utf-8',
        }
    })
    .then(res=>res.data)
}