import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import Collapse from  '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Checkbox from  '@mui/material/Checkbox';

const styleBox = {
    gap:'1rem',
    marginBottom:'0.5rem',
    border: '1px solid #e0e0e0',
    borderRadius:'8px',
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 1rem',
    '&:hover':{
        border: '1px solid #000',
    }
}

const FilterAvailability = ({
    requestIsLoad,
    isBuyNow,
    setIsBuyNow,
    isAuction,
    setIsAuction,
    countNfts,
    pageNftRef,
    setSliceNFT,
    setNewContent,
    resetFilter,
    changeFilter,
    setUrlNFT,
    limit,
    listNFT,
    removeFilter
    }) => {
    const { t } = useTranslation("translate");
    const [Availability, setAvailability] = useState(false);
    const history = useNavigate();

    const handleClickStatus= (check,value) => {
        countNfts.current = listNFT;
        pageNftRef.current = 0;
        setSliceNFT(10)
        setNewContent([])
        resetFilter("availability");
        if(value == 'ON_SALE'){
            changeFilter.current = true;
            setIsAuction(false)
            setIsBuyNow(check)
        }
        if(value == 'LIVE_AUCTION'){
            changeFilter.current = true;
            setIsAuction(check)
            setIsBuyNow(false)
        }
        if(check){
            history(`/explore?limit=${limit}&page=0&order=created&${value}=TRUE`)
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${limit}&page=0&order=created&${value}=TRUE`)
        }else{
            removeFilter()
        }
    }
    return (
        <Box
            component='article'
            display='flex'
            flexDirection='column'
            sx={{
                borderBottom:'1px solid #e0e0e0',
            }}   
        >
            <Box
                component='article'
                display='flex'
                justifyContent='space-between'
                alignItems='center'  
            >
                <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.availability")}</h1>
                <span onClick={()=>setAvailability(!Availability)}>{!Availability ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}</span>
            </Box>
            <Collapse in={Availability} timeout="auto" unmountOnExit>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styleBox}
                    style={{
                        backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                    }}
                    onClick={()=>!requestIsLoad && handleClickStatus(!isBuyNow,'ON_SALE')}
                >
                    <Box 
                        display='flex' 
                        alignItems='center'
                        sx={{
                            gap:'0.5rem',
                        }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isBuyNow}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.buy_now")}</h1>
                    </Box>
                </Box>
                
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styleBox}
                    style={{
                        backgroundColor: requestIsLoad ? '#E0E0E0':'transparent'
                    }}
                    onClick={()=>!requestIsLoad && handleClickStatus(!isAuction,'LIVE_AUCTION')}
                >
                    <Box 
                        display='flex' 
                        alignItems='center'
                        sx={{
                            gap:'0.5rem'
                        }}>
                            <Checkbox 
                                disabled={requestIsLoad}
                                checked={isAuction}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                        <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.live_auction")}</h1>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

FilterAvailability.propTypes = {
    requestIsLoad: PropTypes.bool,
    isBuyNow: PropTypes.bool,
    setIsBuyNow: PropTypes.func,
    isAuction: PropTypes.bool,
    setIsAuction: PropTypes.func,
    countNfts: PropTypes.object,
    pageNftRef: PropTypes.object,
    setSliceNFT: PropTypes.func,
    setNewContent: PropTypes.func,
    resetFilter: PropTypes.func,
    changeFilter: PropTypes.object,
    setUrlNFT: PropTypes.func,
    limit: PropTypes.number,
    listNFT: PropTypes.number,
    removeFilter: PropTypes.func
};

export default FilterAvailability;