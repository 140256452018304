import React ,{useContext, useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import PropTypes from 'prop-types';
import CardAuctionProfile from './components/CardAuctionProfile'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModalCustom from 'components/ModalCustom';
import FormMyData from './components/FormMyData';
import DefaultCard from 'assets/images/preview2.jpg'
import CloseIcon from '@mui/icons-material/Close';
import OutsideClickHandler from 'react-outside-click-handler';
import ButtonStyled from 'components/ButtonStyled';
import { DrawerMobileContext } from 'hooks/DrawerMobileContext';
import PresentationBackground from 'assets/background/newBackground.png';
import axios from 'axios';
import { getFollowers, getFollowing } from 'services/User/getFollowers';
import { deleteFollower } from 'services/User/deleteFollower';
import { useTranslation } from 'react-i18next';

const ShowAvatar = ({user,formEditProfile,data,address,onFunction,load}) => {
    return (
        <Box
            sx={{
                position:'relative',
                width: 120, height: 120,
            }}
        >
            <Avatar variant="square"
                src={user && user.profile_pic_url && formEditProfile.avatar == '' ? user.profile_pic_url : formEditProfile.avatar}
                sx={{ width: 120, height: 120,borderRadius:'8px 8px'}}
            />
                {  
                    data && data.userAccount != 'undefined' && (address+'').toUpperCase().includes((data.userAccount+'').toUpperCase()) &&
                    <Box sx={{position:'absolute',bottom:'3px',right:'3px'}}>
                        {
                            load ?
                            <Box
                                sx={{
                                    width:'30px',
                                    height:'30px',
                                    border:'1px solid #fff',
                                    backgroundColor:'#fff',
                                    borderRadius:'50% 50%',
                                    boxShadow:'0px 0px 5px #000',
                                    display:'flex',
                                    justifyContent:'center',
                                    alignItems:'center'
                                }}
                            >
                                <CircularProgress size={20} sx={{color:'#000'}} />
                            </Box>
                            :
                            <Box
                                sx={{
                                    width:'30px',
                                    height:'30px',
                                    border:'1px solid #fff',
                                    backgroundColor:'#fff',
                                    borderRadius:'50% 50%',
                                    cursor:'pointer',
                                    boxShadow:'0px 0px 5px #000',
                                }}
                                onClick={onFunction}
                            >
                                <ModeEditIcon sx={{color:'#000',fontSize:'20px',mt:'3px',ml:'3px'}} />
                            </Box>
                        }
                        
                    </Box>
                }
        </Box>
    )
}

ShowAvatar.propTypes = {
    user: PropTypes.object,
    formEditProfile: PropTypes.object,
    data: PropTypes.object,
    address: PropTypes.string,
    onFunction: PropTypes.func,
    load: PropTypes.bool
}

const IsArtistOrCurator = ({user}) =>{
    const {t} = useTranslation("translate");
    return (
        <Box
            sx={{
                display:'flex',
                justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'center',xl:'center'},
                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                fontSize:{xs:'22px',sm:'22px',md:'24px',lg:'26px'},
                fontWeight:'bold',
                color:{xs:'#000',sm:'#000',md:'#fff'}
            }}
        >
            {
            user && user.role > 0 && user.role == 5 ?
                t("profile.type_user_curator")
                :
                (user && user.role == 2) &&
                t("profile.type_user_artist")
            }
        </Box>
    )
}

IsArtistOrCurator.propTypes = {
    user: PropTypes.object
}

const Header = ({
    formEditProfile,
    user,
    address,
    data,
    getMyDataProfile,
    load,
    openModalForm,
    setOpenModalForm,
    setFormEditProfile,
    setInitEditProfile,
    isSetEditProfile,
    dataTemp,
    setDataTemp,
    openPopText,
    setOpenPopText,
    myNFTRandom
}) => {
    const { t } = useTranslation("translate");
    const {setOpenWallet } = useContext(DrawerMobileContext);
    const [followerDisabled,setFollowerDisabled] = React.useState(false);
    const [followersCount, setFollwersCount] = React.useState(0)
    const [followingCount, setFollowingCount] = React.useState(0)
  
    const handleClickSocialMedia = (link) =>{
        if(link != ''){
            window.open(link, '_blank');
        }
    }

    const handleOpenModal = async() => {
        try{
            getMyDataProfile();
        }catch(e){
            console.log(e);
        }
    }
    const init = async () =>{
        const followers = await getFollowers(address)
        const following = await getFollowing(address)
        if(data && data.userAccount) {
            if (followers) {
                let follow = followers.find(x => x.follower.toUpperCase() == data.userAccount.toUpperCase())
                if (follow) {
                    setFollowerDisabled(true)
                }
                setFollwersCount(followers.length)
            }
            if (following) setFollowingCount(following.length);
        }
    }
    useEffect(() => {
        init();
    },[data])

    const [defaultObj,setDefaultObj] = useState(
        {
            metadata:{
                json_data:{
                    name:''
                },
                is_video : false,
            },
            thumb_url_large:DefaultCard,
            thumb_resize:DefaultCard,
            user:{
                profile_pic_url:'',
                username:'',
                wallet:'',
            },
            user_bid:{
                username:'',
            },
            auction:false
        }
    );
    useEffect(() => {
        if(user){
            setDefaultObj(
                {
                    metadata:{
                        json_data:{
                            name:''
                        },
                        is_video : false,
                    },
                    thumb_url_large:DefaultCard,
                    thumb_resize:DefaultCard,
                    user:{
                        profile_pic_url:user != null && user.profile_pic_url != '' ?user.profile_pic_url  :DefaultCard,
                        username:user != null && user.username != '' ? user.username:'',
                        wallet:address,
                    },
                    user_bid:{
                        username:user != null && user.user_bid != '' ? user.user_bid:'',
                    },
                    auction:false
                }
            )
        }
    },[user])


    const handleFollow = async()=>  {
        if(data && data.userAccount&&data.userAccount != 'undefined') {
            if(followerDisabled) {
                await  deleteFollower(address,data.userAccount)
                window.location.reload()
            } else {
                const url = `${process.env.REACT_APP_URL_API}/follow?domain=${process.env.REACT_APP_DOMAIN}`
                let dataForm = {
                    wallet : address,
                    follower : data.userAccount
                }
                axios.post(url,dataForm,{
                    headers:{
                        'Content-Type': 'text/plain;charset=utf-8',
                    }
                }).then((r) => {
                    console.log('r ::',r)
                    setFollowerDisabled(true)
                })
            }
        } else {
            alert("Please login")
        }
    }

    return (
        <React.Fragment>
            <Container
                maxWidth="xl"
                sx={{
                    position:'relative'
                }}
            >
                 {
                    openPopText &&
                    <Box
                        sx={{
                            position:'absolute',
                            top:'0px',
                            right:'0px',
                        }}
                    >
                        <Box
                            sx={{
                                width: {xs:'100%',sm:'328px',md:'328px'},
                                height:  {xs:'170px',sm:'170px',md:'170px'},
                                backgroundColor: '#f2f2f2 !important',
                                position: 'fixed',
                                bottom: {xs:'0px',sm:'15px'},
                                right: {xs:'0px',sm:'15px'},
                                zIndex: '99999 !important',
                                boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.5)',
                                "&:hover": {
                                    opacity: '1',
                                
                                }
                            }}
                        >
                            <OutsideClickHandler onOutsideClick={()=>setOpenPopText(false)}>      
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Box  
                                        sx={{
                                            pt:'10px',
                                            pr:'10px'
                                        }}
                                    >
                                        <CloseIcon onClick={()=>setOpenPopText(false)}
                                            sx={{
                                                fontSize: '30px',
                                                fontWeight: 'bold',
                                                cursor: 'pointer'
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: '0px 10px',
                                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                        fontSize: {xs:'22px',sm:'22px',md:'20px'},
                                        textAlign: 'center'
                                    }}
                                >
                                    {t("profile.register_pop.register_your_email_text")} <br/> {t("profile.register_pop.complete_your_profile_text")}
                                </Box>
                                <Box
                                    sx={{
                                        width: '100%',
                                        p: '0px 10px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                    }}
                                >
                                    <ButtonStyled
                                        text={t("profile.register_pop.here_btn")}
                                        onClick={async()=>{setOpenPopText(false); setOpenWallet(true)}}
                                    />
                                </Box>
                            </OutsideClickHandler>
                        </Box>
                    </Box>
                }
                <Box
                    sx={{
                        width:'100%',
                        height:'100%'
                    }}
                >
                    <Box
                        sx={{
                            width:'100%',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'center',
                            mb: '50px'
                        }}
                    >                
                        <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
                            flexDirection={{xs:'column-reverse',sm:'column-reverse',md:'row'}}
                            margin={"0px 0px"}
                            padding={"0px 0px"}
                            sx={{
                                width:'100%'
                            }}
                            justifyContent={"center"}
                        >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} 
                                sx={{
                                    width:'100%',
                                    marginTop:{xs:'0px',sm:'0px',md:'30px'},
                                    height:'100%',
                                }}
                            >
                                <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
                                    justifyContent="center"
                                    sx={{
                                        width:'100%',
                                        ml:{xs:'5px',sm:'0px',md:'0px',lg:'50px',xl:'50px'},
                                    }}
                                >   
                                    <Grid item xs={12} sm={10} md={10} lg={9} xl={7} 
                                        sx={{
                                            width:'100%',
                                            marginTop:{xs:'0px',sm:'0px',md:'30px'},
                                            height:'100%',
                                        }}
                                    >
                                        <CardAuctionProfile item={myNFTRandom && [myNFTRandom].length > 0 ? myNFTRandom:defaultObj} index={0} isYour={String(address).toUpperCase().includes(String(data.userAccount).toUpperCase())} />
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} 
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                    marginTop:{xs:'0px',sm:'0px',md:'30px'}
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'center',   
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:{xs:'100%',sm:'100%',md:'400px',lg:'400px',xl:'400px'},
                                            maxWidth:{xs:'100%',sm:'100%',md:'400px',lg:'400px',xl:'400px'},
                                            minWidth:{xs:'100%',sm:'100%',md:'400px',lg:'400px',xl:'400px'},
                                            boxSizing:'border-box',
                                            m:'0 auto',
                                            height:'100%',
                                            position:'relative'
                                        }}
                                    >

                                        <Box
                                            sx={{
                                                width:'100%',
                                                height:'100%',
                                                boxSizing:'border-box',
                                                background:{xs:'transparent',sm:'transparent',md:'#000'},
                                                mt:{xs:'30px',sm:'30px',md:'30px',lg:'30px'}
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display:{xs:'flex',sm:'flex',md:'none'},
                                                    justifyContent:'center'
                                                }}
                                            >
                                                <ShowAvatar 
                                                    user={user}
                                                    formEditProfile={formEditProfile}
                                                    data={data}
                                                    address={address}
                                                    onFunction={handleOpenModal}
                                                    load={load}
                                                />
                                            </Box>

                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    display:'flex',
                                                    height:'150px',
                                                    justifyContent:{xs:'flex-start',sm:'flex-start',md:'flex-start',lg:'center',xl:'center'},
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width:'100%',
                                                        display:'grid',
                                                        gridTemplateColumns:{xs:'repeat(1,1fr)',sm:'repeat(1,1fr)',md:'repeat(2,1fr)',lg:'repeat(1,1fr)',xl:'repeat(1,1fr)'}
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            position:{xs:'relative',sm:'relative',md:'relative',lg:'absolute'},
                                                            top:'0px',
                                                            left:{xs:'0px',sm:'0px',md:'0px',lg:'-15%'},
                                                            m:{xs:'15px 10px',sm:'15px 10px',md:'15px 10px',lg:'0px 0px'},
                                                            display:{xs:'none',sm:'none',md:'block'}
                                                        }}
                                                    >
                                                            <ShowAvatar 
                                                                user={user}
                                                                formEditProfile={formEditProfile}
                                                                data={data}
                                                                address={address}
                                                                onFunction={handleOpenModal}
                                                                load={load}
                                                            />
                                                    </Box>
                                                    <Box
                                                        sx={{
                                                            width:{xs:'100%',sm:'100%',md:'200px',lg:'400px',xl:'400px'},
                                                            mt:'15px',
                                                            mb:'15px',
                                                            display:'flex',
                                                            justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'center',xl:'center'},
                                                            flexDirection:'column'
                                                        }}
                                                    >
                                                        <IsArtistOrCurator
                                                            user={user}
                                                        />

                                                        <Box
                                                            sx={{
                                                                minWidth:{xs:'60%',sm:'60%',md:'80%',lg:'60%'},
                                                                maxWidth:{xs:'60%',sm:'60%',md:'80%',lg:'60%'},
                                                                m:{xs:'0 auto',sm:'0 auto',md:'0px 0px',lg:'0 auto'},
                                                                boxSizing:'border-box',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textAlign: {xs: 'center',sm:'center',md: 'left', lg: 'center'}
                                                            }}
                                                        >
                                                            <Box
                                                                component="a" href={`${process.env.REACT_APP_SCAN}address/${String(address+'').toLowerCase()}`}
                                                                target="_blank" 
                                                                rel="noreferrer"
                                                                sx={{
                                                                    textDecoration:'none',
                                                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif',
                                                                    fontSize:{xs:'20px',sm:'20px',md:'20px',lg:'24px'},
                                                                    color:{xs:'#000',sm:'#000',md:'#fff'}
                                                                }}
                                                            >
                                                                {
                                                                    formEditProfile && formEditProfile.username && formEditProfile.username != '' && formEditProfile.username != address  ?
                                                                    '@'+formEditProfile.username
                                                                    :
                                                                    '@'+(address).substring(0,5)+ '...' +(address).substring(38,54)
                                                                }
                                                            </Box>
                                                        </Box>
                                                        {
                                                        formEditProfile.country != '' && 
                                                        <Box
                                                            sx={{
                                                                minWidth:{xs:'60%',sm:'60%',md:'80%',lg:'60%'},
                                                                maxWidth:{xs:'60%',sm:'60%',md:'80%',lg:'60%'},
                                                                m:{xs:'0 auto',sm:'0 auto',md:'0px 0px',lg:'0 auto'},
                                                                boxSizing:'border-box'
                                                            }}
                                                        >
                                                            <Box
                                                                sx={{
                                                                    width:'100%',
                                                                    display:'flex',
                                                                    justifyContent:{xs:'center',sm:'center',md:'flex-start',lg:'center'},
                                                                    alignItems:'center',
                                                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                                                    fontSize:{xs:'20px',sm:'18px',md:'18px',lg:'24px'},
                                                                    color:{xs:'#000',sm:'#000',md:'#fff'},
                                                                    mb:'10px'
                                                                }}
                                                            >
                                                                <span>{formEditProfile.country}</span>
                                                            </Box>
                                                        </Box>
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Divider sx={{background:{xs:'#000',sm:'#000',md:'#fff'}}} />
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    p:'10px 0px',
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    color:{xs:'#000',sm:'#000',md:'#000',lg:'#fff'},
                                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                                    fontSize:{xs:'18px',sm:'13px',md:'15px',lg:'20px'},
                                                    gap:'10px'
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        alignItems:'center',
                                                        gap:'5px'
                                                    }}
                                                >
                                                    <InstagramIcon 
                                                        onClick={()=>handleClickSocialMedia(formEditProfile.social_media.instagram !='' ? formEditProfile.social_media.instagram :'')}
                                                        sx={{color:{xs:'#000',sm:'#000',md:'#fff',lg:'#fff'},fontSize:{xs:'22px',sm:'22px',md:'22px',lg:'26px',cursor:'pointer'}}}
                                                    />
                                                    <Box
                                                        onClick={()=>handleClickSocialMedia(formEditProfile.social_media.twitter != '' ? formEditProfile.social_media.twitter :'')}
                                                        sx={{
                                                            background:{xs:'#000',sm:'#000',md:'#fff'},
                                                            borderRadius:'50% 50%',
                                                            width:{xs:'18px',sm:'18px',md:'20px'},
                                                            height:{xs:'18px',sm:'18px',md:'20px'},
                                                            display:'flex',
                                                            justifyContent:'center',
                                                            alignItems:'center',
                                                            cursor:'pointer'
                                                        }}
                                                    >
                                                        <TwitterIcon sx={{color:{xs:'#fff',sm:'#fff',md:'#000'},fontSize:{xs:'14px',sm:'14px',md:'15px',lg:'18px'}}} />
                                                    </Box>
                                                </Box>

                                            </Box>
                                            <Divider sx={{background:{xs:'#000',sm:'#000',md:'#fff'}}} />
                                            <br/>
                                            <Box>
                                                <Box
                                                    sx={{
                                                        width:'100%',
                                                        display:'grid',
                                                        gridTemplateColumns:'repeat(3,1fr)',
                                                        color:{xs:'#000',sm:'#000',md:'#fff'},
                                                        fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                                        fontSize:{xs:'13px',sm:'13px',md:'15px',lg:'20px'}
                                                    }}>
                                                    <CounterInfoBox
                                                        text='NFTs'
                                                        howMany={user != null && user.nfts ? user.nfts:0}/>

                                                    <CounterInfoBox
                                                        textRef='followers'
                                                        howMany={followersCount}/>

                                                    <CounterInfoBox
                                                        textRef='following'
                                                        howMany={followingCount}/>
                                                </Box>
                                                <br/>
                                                {data && data.userAccount != null && data.userAccount != 'undefined' && (data.userAccount.toUpperCase() != address) &&
                                                <Box display='flex' justifyContent='center'>
                                                        <Button 
                                                            type='button' 
                                                            onClick={handleFollow} 
                                                            sx={{
                                                                backgroundImage:`url(${PresentationBackground})`, 
                                                                backgroundRepeat:'no-repeat',
                                                                backgroundSize:'cover',
                                                                color:'#fff'
                                                            }}
                                                        >
                                                            { followerDisabled ? t("profile.un_follow_btn") : t("profile.follow_me_btn") }
                                                        </Button>
                                                </Box>}
                                                <br/>
                                                <Box
                                                    sx={{
                                                        width:'100%',
                                                        display:'flex',
                                                        justifyContent:'center',
                                                        pb:'30px'
                                                    }}
                                                >
                                                    {
                                                        load ?
                                                        <CircularProgress size={30} sx={{color:{xs:'#000',sm:'#000',md:'#fff'}}} />
                                                        :
                                                        <Box
                                                            sx={{
                                                                width:'90%',
                                                                textAlign:'center',
                                                                color:{xs:'#000',sm:'#000',md:'#fff'},
                                                                fontSize:{xs:'12px',sm:'12px',md:'14px',lg:'14px'},
                                                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                                                opacity:0.5,
                                                                whiteSpace:'pre-line'
                                                            }}
                                                        >
                                                            {formEditProfile.description}
                                                        </Box>
                                                    } 
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>

                </Box>
            </Container>
           
            <ModalCustom 
                isOpen={openModalForm}
                onCloseModal={()=>setOpenModalForm(false)}
                title={"Edit profile"}    
                height={'auto'}    
            >
                <Box
                    sx={{
                        width:'100%',
                        height:{xs:'90vh',sm:'90vh',md:'100%',lg:'100%',xl:'auto'},
                        overflowY:'auto'
                    }}
                >
                    <FormMyData 
                        formEditProfile={formEditProfile}
                        setFormEditProfile={setFormEditProfile}
                        setInitEditProfile={setInitEditProfile}
                        isSetEditProfile={isSetEditProfile}
                        user={user}
                        data={data}
                        dataTemp={dataTemp}
                        setDataTemp={setDataTemp}
                        setOpenModalForm={setOpenModalForm}
                    />
                </Box>
            </ModalCustom>
        </React.Fragment>
    )
}

const CounterInfoBox = ({text, textRef, howMany}) => {
    const { t } = useTranslation("translate");

    const getText = () => {
        if (text) return text;
        if (textRef) return t(`profile.${textRef}`);
        return ""
    }

    return (
        <>
            <Box sx={{width:'100%', display:'flex', justifyContent:'center'}}>
                <Box sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    textAlign:'center'}}>
                <Box>{howMany}</Box>
                    <Box>{getText()}</Box>
                </Box>
            </Box>
        </>
    );
}

Header.propTypes = {
    formEditProfile: PropTypes.object,
    user: PropTypes.object,
    address: PropTypes.string,
    data: PropTypes.object,
    getMyDataProfile: PropTypes.func,
    load: PropTypes.bool,
    openModalForm: PropTypes.bool,
    setOpenModalForm: PropTypes.func,
    setFormEditProfile: PropTypes.func,
    setInitEditProfile: PropTypes.func,
    isSetEditProfile: PropTypes.bool,
    dataTemp: PropTypes.object,
    setDataTemp: PropTypes.func,
    openPopText: PropTypes.bool,
    setOpenPopText: PropTypes.func,
    myNFTRandom: PropTypes.object
}


export default Header;
