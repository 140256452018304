import React from 'react';
import PropTypes from 'prop-types';
import { Image } from "semantic-ui-react";
import { Box,Card, CardContent,Grid} from "@mui/material";
import { Link } from 'react-router-dom';
import styled from "@emotion/styled/macro";

export const CardContentCustom = styled(Box)({
  borderRadius: '8px 8px 0px 0px',
  background: 'transparent',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "#FFF",
  padding:'none',
  position: "relative",
  width:'100%',
  height:'100%',
  cursor: "pointer",
  boxShadow: "none",
//  border: "1px solid #e3e3e3",
  [`:hover ${Image}`]: {
    backgroundColor: "rgba(0,0,0,.5)",
  },

  [`:hover ${Image}`]: {
    opacity: 1,
  },
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
      //transform: 'translateY(-2px)',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
      /* select class */
      '& .card-collection': {
        transform: 'scale(1.06)',
      },
      '& .is-video-collection': {
        transform: 'scale(3.08)',
      }
  },
  ["@media (max-width: 320px)"]: {
    height: '330px'
  }
});

const CardCategories =  ({item,width,index}) => {

    return (
      <React.Fragment>
        <Box
          sx={{
            width: '100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            mt:1,
            mb:1
          }}
        >
          <Box component={Card} 
            sx={{ 
              width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
              //marginLeft:{xs:'0px',sm:'0px',md:'-20px'},
              "@media screen and (min-width: 2880px)":{
              width:`${Number( (width / 5) - 60)}px`
              },
              "@media screen and (min-width: 3580px)":{
                  width:'490px'
              }
            }}
          >
            <CardContentCustom
              sx={{          
                width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                height:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                "@media screen and (min-width: 2880px)":{
                    width:`${Number( (width / 5) - 60)}px`,
                    height:`${Number( (width / 5) - 60)}px`
                },
                "@media screen and (min-width: 3580px)":{
                    width:'490px',
                    height:'490px'
                }
              }}
            >
              <Link 
                to={`/explore?limit=100&order=created&key_name=CATEGORY&key_val=${item.category_name}`}
                style={{
                    textDecoration: 'none',
                }}
              >
                <Image
                      draggable={false}
                      style={{ width: "100%", height: "100%" }}
                      src={item.thumb_url_large}
                />
              </Link>
            </CardContentCustom>
          </Box>
    
        </Box>

        
        <Grid item  xs={12} sm={12} md={12} lg={12} xl={12}
          sx={{
            display:'none',
            width:{xs:'calc(100% - 10px)',sm:'calc(100% - 20px)',md:'calc(100% - 40px)',lg:'calc(100% - 70px)'},
          }}
        >
          <CardContent
            sx={{
              padding:'0px 0px',
              width: "100%",
              height: "100%",
              "&:last-child":{
                padding:'5px 0px'
              }
            }}
          >
            <Card
              className={`${width}-w`}
              draggable={false}
              item={index}
              sx={{
                width: "100%",
                height: "100%",
                position:'relative'
              }}
            >
              <Link
                to={`/explore?limit=100&order=created&key_name=CATEGORY&key_val=${item.category_name}`}
                style={{
                    textDecoration: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none'
                }}
              >
                <Box
                    sx={{
                      position:'absolute',
                      left:'0px',top:'0px',
                      width: "100%",
                      height: "100%"
                    }}
                  >
                    <Box
                          sx={{
                            width:'100%',
                            height: "100%",
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'flex-end'
                          }}
                      >
                        <Box
                            sx={{
                                width:'auto',
                                boxSizing:'border-box',
                                maxWidth:'100%'
                            }}
                        >
                          <Box
                              sx={{
                                  width:'100%',
                                  boxSizing:'border-box',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  padding:'0.5rem',
                                  borderRadius: '10px',
                                  fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                  fontSize:{xs:'23px',sm:'28px',md:'25px',lg:'27px',xl:'30px'},
                                  fontWeight:'bold',
                                  color:'#F2F2F2',
                                  WebkitUserSelect: 'none',
                                  MozUserSelect: 'none',
                                  msUserSelect: 'none',
                                  userSelect: 'none',
                                  pb:'20px',
                                  textAlign:'center'
                              }}
                          >
                            {item && item.category_name} 
                          </Box>
                        </Box>
                    </Box> 
                </Box>
                <Box
                  sx={{
                      width: "100%",
                      height:{xs:'300px',sm:'340px',md:'250px',lg:'310px',xl:'480px'},
                  }}
                >
                  <Image
                      draggable={false}
                      style={{ width: "100%", height: "100%" }}
                      src={item.thumb_url_large}
                  />
                </Box>
              </Link>
            </Card>
          </CardContent>
        </Grid>
      </React.Fragment>
    );
};

CardCategories.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardCategories;