import React, { useEffect, useState } from 'react'
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import LoaderModal from 'components/LoaderModal';
import { Box } from '@mui/material';


const NFTUserRegisteredTab = () => {
    const [users, setUsers] = useState([])
    const [load, setLoad] = useState(true)
    const [msg, setMsg] = useState('')
    const [error, setError] = useState(false)

    const columns = [
        { field: 'id', headerName: 'ID' ,width: 250 },
        { field: 'token_id', headerName: 'TokenID' ,width: 250 },
        { field: 'wallet', headerName: 'Wallet', width: 250 },
        { field: 'username', headerName: 'Username', width: 250 },
        { field: 'email', headerName: 'Email', width: 250 }
    ]

    const handleCheckBid = (res,resUser) =>{
        let users = []
        res.data.forEach((nft,index) => {
            let amount = (nft.last_bid) ? nft.last_bid.amount : 0
            let status = "Pendiente"
            if(amount == 0 || nft.auction && nft.auction.finish_date > 0 && nft.auction.finish_date < Math.floor(Date.now() / 1000)) {
                status = "Concluido"
            }
            let username = ""
            let email = ""
            let user = resUser.data.find(user => user.wallet.toUpperCase() == nft.owner.toUpperCase())
            if(user) {
                email = user.email
                username = user.username
            }
            users.push({id : index, 
                wallet : nft.owner,
                token_id : nft.token_id, 
                movement : 'Subasta', 
                amount_avax : amount+" "+"AVAX", 
                status: status,
                email : email,
                username : username
            })
        })
        setUsers(users)
        setLoad(false)
    }



    useEffect(()=>{
        if(users.length == 0) {
            setLoad(true)
            let nft = `${process.env.REACT_APP_URL_API}/nft?domain=${process.env.REACT_APP_DOMAIN}&limit=${1000}&page=${0}&order=created&key_name=SEARCH&key_val=news`
            axios.get(nft).then(res => {
                axios.get(process.env.REACT_APP_URL_API+`/user?domain=${process.env.REACT_APP_DOMAIN}&limit=${1000}&page=${0}&order=last_seen&key_name=REGISTERED&key_val=REGISTERED`)
                .then(resUser=>{
                    console.log('users ::',res.data)
                    handleCheckBid(res,resUser);
                }).catch(err=>{
                    setLoad(false)
                    setError(true)
                    setMsg('Error: '+err)
                })
            }).catch(err=>{
                setLoad(false)
                setError(true)
                setMsg('Error: '+err)
            })
        }
    },[])
    return (
        <React.Fragment>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                    color:error?'red':'#000'
                }}
            >
                {msg}
            </Box>
            
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                rows={users}
                columns={columns}
                pageSize={ 12}
            />
                <LoaderModal
                    text='Cargando...'
                    isOpen={load}
                    textColor='#fff'
                    spinnerColor='#fff'
                    setIsClosed={()=>{}}
                />
            </div>
        </React.Fragment>
        
    )
}


export default NFTUserRegisteredTab