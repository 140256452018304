import React, { useContext, useEffect, useState } from 'react'
import { Avatar, Box, ListItemButton, ListItemIcon, ListItemText, Modal } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom'
import logoAvalanche from 'assets/logos/avalanche_logo.svg'
import { Context } from 'hooks/WalletContext';
import { switchNetwork } from 'services/web3';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  };

const ModalSwitchNetwork = () => {
    const {t} = useTranslation("translate");
    const {data}= useContext(Context)
    const [network, setNetwork] = useState(false);
    const {pathname} = useLocation()
    useEffect(()=>{
      if(data && data.chainID !== 'NaN') {
        if(data.chainID != process.env.REACT_APP_NETWORK){
            setNetwork(true)
        }else{
            setNetwork(false)
        }
    }
    },[data,pathname])
    const handleChangeNetwork = () => {
        switchNetwork(data.provider)
    }
    return (
        <Modal
            open={network}
            onClose={() => setNetwork(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <h3 style={{textAlign:'center'}}>{t("lottery_container_modal.title")}</h3>
                <ListItemButton
                    onClick={handleChangeNetwork} 
                >
                    <Box  sx={{width: '100%',display:'flex',justifyContent:'center',textAlign:'center', gap:'1.2rem'}}>
                        <ListItemIcon >
                            <Avatar 
                                src={logoAvalanche}
                                alt="metamask" 
                                sx={{
                                    width:'2.5rem',
                                    height:'2.5rem',
                                }}
                            />
                        </ListItemIcon>
                        <Box 
                            sx={{marginLeft:'-10px',
                                display:'flex',
                                alignItems:'center'
                            }}
                        >
                            <ListItemText primary={t("lottery_container_modal.switch_to_polygon_text")} />
                        </Box>
                    </Box>
                </ListItemButton>
            </Box>
        </Modal>
    )
}


export default ModalSwitchNetwork