import React from 'react';
import PropTypes from 'prop-types';
import { Box,Card, CardMedia, Divider , Tooltip} from "@mui/material";
import { Link } from 'react-router-dom';
import styled from "@emotion/styled/macro";
import { useTranslation } from 'react-i18next';
import { getThumbnail } from 'services/Thumbail/getThumbnail';

export const BigTitle = styled(Box)({
  fontSize: "30px",
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '18rem',
  display:'inline-block',
  fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
  ["@media screen and (max-width: 400px)"]: {
    fontSize: "15px"
  }
});

export const BackgroundNewCard = styled(Card)({
  borderRadius: '8px 8px 0px 0px ',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "#FFF",
  position: "relative",
  width:'100%',
  cursor: "pointer",
  border: "1px solid transparent",
  boxShadow: "none",
  transition: 'all 0.3s ease-in-out',
  ["@media (max-width: 320px)"]: {
    height: '330px'
  }
});

export const CardContentCustom = styled(Card)({
  borderRadius: '8px 8px 0px 0px',
  background: 'transparent',
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  color: "#FFF",
  padding:'none',
  position: "relative",
  width:'100%',
  height:'100%',
  cursor: "pointer",
  boxShadow: "none",
//  border: "1px solid #e3e3e3",
  [`:hover ${CardMedia}`]: {
    backgroundColor: "rgba(0,0,0,.5)",
  },

  [`:hover ${CardMedia}`]: {
    opacity: 1,
  },
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
      //transform: 'translateY(-2px)',
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
      /* select class */
      '& .card-collection': {
        transform: 'scale(1.06)',
      },
      '& .is-video-collection': {
        transform: 'scale(3.08)',
      }
  },
  ["@media (max-width: 320px)"]: {
    height: '330px'
  }
});



const CardNFT =  ({item,width}) => {
  const { t } = useTranslation("translate");
  return (
    <React.Fragment>
      <Box
        sx={{
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          mt:1,
          mb:1
        }}
      >
        <Box component={Card} 
          sx={{ 
            width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
            //marginLeft:{xs:'0px',sm:'0px',md:'-20px'},
            "@media screen and (min-width: 2880px)":{
              width:`${Number( (width / 5) - 60)}px`
            },
            "@media screen and (min-width: 3580px)":{
              width:'490px'
            }
          }}
        >
          <CardContentCustom
            sx={{          
              width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
              height:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
              "@media screen and (min-width: 2880px)":{
                width:`${Number( (width / 5) - 60)}px`,
                height:`${Number( (width / 5) - 60)}px`
              },
              "@media screen and (min-width: 3580px)":{
                width:'490px',
                height:'490px'
              }
            }}
          >
            <Link 
              to={`/nft?address=${item.project_key}&token_id=${item.token_id}&domain=${process.env.REACT_APP_DOMAIN}`}
              style={{
                  textDecoration: 'none',
              }}
            >
              {
              item && item.metadata.is_video ?
              <CardMedia
                  className={'card-collection'}
                  component={'video'}
                  src={item && item.thumb_url}
                  autoPlay
                  loop
                  muted
                  sx={{
                      position:'relative',
                      borderRadius: '8px',
                      height:'100%',
                      width:'100%',
                      margin:'0 auto',
                      transform: 'scale(2)'
                  }}
              />
              :
              <CardMedia
                  className={'card-collection'}
                  component={'img'}
                  src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                  sx={{
                      position:'relative',
                      borderRadius:'10px 10px 0px 0px',
                      height:'100%',
                      width:'100%',
                      margin: '0 auto',
                  }}
              />
              }
            </Link>
          </CardContentCustom>
          <Box
              sx={{
                p:1,
                height: "110px",
                width: "calc(100% - 0px)"
              }}
            >
              <Box
                className="notranslate"
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tooltip title={item && item.metadata && item.metadata && item.metadata.json_data && item.metadata.json_data.name}  placement="top">
                  <Box
                    component={BigTitle}
                    sx={{
                      maxWidth: { xs:'100%', sm:'100%', md:'100%', lg:'100%', xl:'100%' },
                      minWidth: { xs:'100%', sm:'100%', md:'100%', lg:'100%', xl:'100%' },
                      fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                      fontSize:'20px',
                      fontWeight:'bold',
                      color:'#0D0D0D',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }}
                  >
                    {item && item.metadata && item.metadata && item.metadata.json_data && item.metadata.json_data.name}
                  </Box>
                </Tooltip>
              </Box>
              <Box
                className="notranslate"
                sx={{
                  maxWidth: { xs:'100%', sm:'100%', md:'70%', lg:'70%', xl:'70%' },
                  minWidth: { xs:'100%', sm:'100%', md:'70%', lg:'70%', xl:'70%' },
                  fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                  fontSize:'19px',
                  color:'#0D0D0D',
                  opacity:0.7,
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden'
                }}
              >
                {
                  item && item.user && item.user.username && item.user.username != item.user.username.substring(0,12)+'com' ? item.user.username
                  :
                  item && item.user &&  item && item.user.wallet && (item.user.wallet).substring(0,5)+ '...' +(item.user.wallet).substring(38,54)
                }
              </Box>
              <Divider/>
              { item.sale &&
                <Box
                  sx={{
                    mt:'5px',
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection:'row'
                  }}
                >
                
                  <Box
                    sx={{
                      fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                      fontSize:'19px',
                      color:'#0D0D0D',
                      opacity:0.7
                    }}
                  >
                    {t("cards.sale.price_text")}
                  </Box>
                  <Box
                    sx={{
                      fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                      fontSize:'17.5px',
                      color:'#0D0D0D',
                      opacity:0.7,
                      mt:'5px',
                    }}
                  >
                    {item && item.sale && item.sale.price && item.sale.price + ' ' + item.sale.coin }
                  </Box>
                </Box>
              }
              
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

CardNFT.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardNFT;