import React from 'react'
import TopBar from './Topbar'
import Footer from './Footer'
import { Box } from '@mui/material'
import PropTypes from 'prop-types'
import PresentationBackground from 'assets/background/newBackground.png'
import MenuMobileBackground from 'assets/background/MenuMobile.png'
import NewsLetters from 'components/FloatComponent'

const Main = ({ children }) => {
  
    return (
        <Box
            sx={{
                position:'absolute',
                width:'100%',
                height:'100%'
            }}
        >
            <TopBar />
                <Box 
                    sx={{
                        width:'100%',
                        height:{xs:'60px',sm:'60px',md:'88px'},
                        backgroundImage:{xs:`url(${MenuMobileBackground})`,sm:`url(${PresentationBackground})`},
                        backgroundSize: 'cover',
                        backgroundPosition: 'cover',
                        backgroundRepeat:'no-repeat',
                    }}
                />
                <Box
                    sx={{
                        width:'100%',
                        minHeight: window.screen.height,
                        overflowX: 'hidden'
                    }}
                >
                    {children}
                </Box>
                <NewsLetters />
            <Footer />
        </Box>
    )
}

Main.propTypes = {
    children: PropTypes.node,
}

export default Main