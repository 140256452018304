import React, { useEffect, useState } from 'react'
import { Avatar, Grid, Box, Container, CardMedia, Tooltip } from '@mui/material';
import {BiShareAlt} from 'react-icons/bi'
import { useTranslation } from 'react-i18next';
import ModalShare from './components/ModalShare';
import { useLocation } from 'react-router';
import { AccountLink, AccountLinkImage, ContractLink } from './styles/styles';
import PropTypes from 'prop-types';
import Web3 from 'web3';

const TypeBox = ({nft,type})=>{
    const { t } = useTranslation("translate");
    if(type == "creator"){
        return (
            <Box 
                sx={{
                    display:'flex',
                    flexDirection:'column',
                    alignItems:'center',
                    justifyContent:'center',
                    boxSizing:'border-box',
                    padding:'5px',
                    border:'1px solid #e5e5e5',
                    minHeight:'105px',
                    '@media (max-width: 767px)': {
                        borderTopRightRadius: '8px',
                    }
                }}
            >
                {t('nft-navInfo.creator')}
                <AccountLinkImage to={`/profile?address=${nft.project.user.main_key}`}>
                    <Avatar alt={nft.project.user.short_main_key} src={`${nft.project.user.profile_pic_url}`} />
                </AccountLinkImage>
                <Box
                    className="notranslate"
                    sx={{
                        width:'100%',
                        textAlign: 'center'
                    }}
                >
                     <AccountLink to={`/profile?address=${nft.project.user.main_key}`}>
                        <Tooltip 
                            title=
                            { 
                                nft && nft.project && nft.project.user && nft.project.user.username && !Web3.utils.isAddress(nft.project.user.username) ?
                                nft.project.user.username
                                :
                                nft.project.user.short_main_key
                            }
                            placement="top"
                        >
                            <Box
                                sx={{
                                    boxSizing:'border-box',
                                    width: 'auto',
                                    maxWidth:'100%',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                            >
                                {
                                    nft && nft.project && nft.project.user && nft.project.user.username && !Web3.utils.isAddress(nft.project.user.username) ?
                                    nft.project.user.username
                                    :
                                    nft.project.user.short_main_key
                                }
                            </Box>
                        </Tooltip>   
                    </AccountLink>
                </Box>
            </Box>
        )
    }

    return (
        <Box 
            sx={{
                width:'100%',
                display:'flex',
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'center',
                boxSizing:'border-box',
                padding:'5px',
                border:'1px solid #e5e5e5',
                minHeight:'105px',
                
            }}
        >
            {t('nft-navInfo.owner')}
            <AccountLinkImage to={`/profile?address=${nft && nft.user.main_key}`}>
                <Avatar alt={nft && nft.user.short_main_key} src={`${nft && nft.user.profile_pic_url}`} />
            </AccountLinkImage>
            <Box
                className="notranslate"
                sx={{
                    width:'100%',
                    textAlign: 'center'
                }}
            >
                <AccountLink to={`/profile?address=${nft && nft.user.main_key }`}>
                    <Tooltip 
                        title=
                        {
                            nft && nft.user && nft.user.username && !Web3.utils.isAddress(nft.user.username) ? 
                            nft.user.username
                            :
                            nft.user.short_main_key
                        }
                        placement="top"
                    >
                        <Box
                            sx={{
                                boxSizing:'border-box',
                                width: 'auto',
                                maxWidth:'100%',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                            }}
                        >
                            {
                                nft && nft.user && nft.user.username && !Web3.utils.isAddress(nft.user.username) ? 
                                nft.user.username
                                :
                                nft.user.short_main_key
                            }
                        </Box>
                    </Tooltip>   
                </AccountLink>
            </Box>
        </Box>
    )
    
}
TypeBox.propTypes = {
    nft: PropTypes.object,
    type: PropTypes.string
}


const NavInfo =  ({nft}) => {
    const { t } = useTranslation("translate");
    const [openModalShare, setOpenModalShare] = useState(false);
    const [url,setUrl] = useState('');
    const location = useLocation(); 
    const handleOpenModalShare = () => {
        setUrl('https://'+ process.env.REACT_APP_DOMAIN+location.pathname + location.search)
        setOpenModalShare(true)
    }

    useEffect(()=>{
        setUrl('https://'+ process.env.REACT_APP_DOMAIN+location.pathname + location.search)
    },[]) 
    
 
    return (
        <Container 
            maxWidth='lg'
            sx={{
                width:'100%',
                marginBottom:'25px', 
                marginTop:'43px',
            }}
        >
           <Grid container columns={{ xs: 2, sm: 3, md: 3, lg: 6 }} justifyContent='center'>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Box 
                        sx={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            boxSizing:'border-box',
                            padding:'5px',
                            border:'1px solid #e5e5e5',
                            minHeight:'105px',
                            borderTopLeftRadius:'8px',
                        }}
                    >
                        {t("nft-navInfo.token_standard")}
                        <h1 style={{marginBottom:'0px'}}>ERC 721</h1>
                    </Box>
                </Grid>
                
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <TypeBox
                        nft={nft}
                        type="creator"
                    />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <TypeBox
                        nft={nft}
                        type="owner"
                    />
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Box 
                        sx={{
                            width:'100%',
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            justifyContent:'center',
                            boxSizing:'border-box',
                            padding:'5px',
                            border:'1px solid #e5e5e5',
                            minHeight:'105px',
                            borderTopRightRadius:'8px',
                            '@media (max-width: 767px)': {
                                borderTopRightRadius: '0px',
                            }
                        }}
                    >
                        {t("nft-navInfo.name")}
                        <AccountLinkImage to={`/collection?address=${nft && nft.project.project_key}`}>
                            <Avatar alt={nft &&  (nft.owner).substring(0,5)+ '...' +(nft.owner).substring(38,54)} src={`${nft && nft.project.thumb_url}`}>
                                <CardMedia
                                    component={nft.project.is_video ? 'video' : 'img'}
                                    src={nft.project.thumb_url}
                                    alt={nft.project.name}
                                    loop
                                    autoPlay
                                    muted
                                    controls={false}
                                    sx={{
                                        transform: 'scale(2)',
                                    }}
                                />
                            </Avatar>
                        </AccountLinkImage>
                        <Box
                            className="notranslate"
                            sx={{
                                width:'100%',
                                textAlign: 'center'
                            }}
                        >
                            <AccountLink to={`/collection?address=${nft && nft.project.project_key}`}>
                                <Tooltip title={nft.project.name+''}  placement="top">
                                    <Box
                                        sx={{
                                            boxSizing:'border-box',
                                            width: 'auto',
                                            maxWidth:'100%',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}
                                    >
                                        {nft.project.name}
                                    </Box>
                                </Tooltip>
                            </AccountLink>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid container columns={{ xs: 2, sm: 3, md: 3, lg: 6 }} justifyContent='center'>
                <Grid item xs={1} sm={1} md={1} lg={2}>
                    <Box 
                        sx={{
                            display:'flex',
                            justifyContent:'center',
                            alignItems:'center',
                            flexWrap: 'wrap',
                            boxSizing:'border-box',
                            padding:'5px',
                            border:'1px solid #e5e5e5',
                            minHeight:'36px',
                            height:'100%',
                            borderBottomLeftRadius:'8px',
                            '@media screen and (max-width: 500px)': {
                                minHeight:'60px',
                            }
                        }}
                    >
                        <span style={{marginRight:'5px', fontWeight:'bold'}}>{t("nft-navInfo.contract")}:</span>
                        <span className="notranslate" style={{fontFamily:'Courier, "Lucida Console", monospace'}}>
                            {
                            nft && nft.project && nft.project.project_key && nft.project.short_project_key ? 
                                <ContractLink href={`${process.env.REACT_APP_SCAN}address/${String(nft.project.project_key+'').toLowerCase()}`} target="_blank" rel="noopener noreferrer">
                                    {nft.project.short_project_key}
                                </ContractLink>
                            : 'Contract is not available'
                            }
                        </span> 
                    </Box>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={2}>
                    <Box 
                        sx={{
                            display:'flex',
                            justifyContent:'space-around',
                            alignItems:'center',
                            boxSizing:'border-box',
                            border:'1px solid #e5e5e5',
                            minHeight:'36px',
                            borderBottomRightRadius:'8px',
                            height:'100%'
                        }}
                    >   
                        <Box onClick={()=>handleOpenModalShare()} sx={{marginTop:'3px', cursor:'pointer'}}>
                            <BiShareAlt size={20} />
                        </Box>

                        <ModalShare 
                            setOpen={setOpenModalShare} 
                            open={openModalShare} 
                            url={url}
                        />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
}

NavInfo.propTypes = {
    nft: PropTypes.object.isRequired,
}

export default NavInfo;
