import React from 'react'
import { Box } from '@mui/material'
import CollectionNFTs from '../CollectionNFTs'
import PropTypes from 'prop-types'

const SectionNFTs = ({content}) => {
    return (
        <Box sx={{marginBottom:'2rem'}}>
            {content &&
            <CollectionNFTs 
                limit={content && content.length}
                content={content} 
            />}
        </Box>
    )
}

SectionNFTs.propTypes = {
    content: PropTypes.array,
}

export default SectionNFTs