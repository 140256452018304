import Web3 from 'web3'
import Contract from './Collectibles.json'

export const isApproved = async(address,from,to) => {
    let web3 = new Web3(process.env.REACT_APP_RPC);
    console.log(address,from,to)
    address = web3.utils.toChecksumAddress(address)
    from = web3.utils.toChecksumAddress(from)
    to = web3.utils.toChecksumAddress(to)
    console.log(address,from,to)
    let contract = new web3.eth.Contract(Contract.abi,address)
    let  approve = await contract.methods.isApprovedForAll(from,to).call()
    console.log('Approve collection',approve)
    return approve
}
