import React, { useEffect, useState }  from 'react'
import { Divider, Box, CardMedia,Grid } from '@mui/material'
import { ImInstagram,ImTwitter } from 'react-icons/im'
import { BsYoutube } from 'react-icons/bs'
import { FaDiscord } from 'react-icons/fa'
import { useTranslation } from 'react-i18next';
import { FooterSectionContent, FooterText } from './styles/styles';
import { Link } from 'react-router-dom';
import ContainerSection from 'components/ContainerSection'
import ArtcryptedWhiteLogo from 'assets/images/png/logosolo_blanco-1.svg'
import UniquePeopleLogo from 'assets/images/png/slogan_blanco_1.svg'
import ArtcryptedTextLogo from 'assets/images/png/artcrypted_b-1.svg'
import PresentationBackground from 'assets/background/newBackground.png'
import MenuMobileBackground from 'assets/background/MenuMobile.png'
import { footer } from 'api/sanity';
import './styles.css';

const Footer = () => {
    const { t } = useTranslation("translate");
    const [marketOptions, setMarketOptions] = useState([]);
    const [legalOptions, setLegalOptions] = useState([]);
    const [socialMedia, setsocialMedia] = useState([])
    useEffect(()=>{
        footer().then(response=>{
            try{
                setMarketOptions(response[0]);
                setLegalOptions(response[0]);
                setsocialMedia(response[0]);
            }catch (e) {
                console.error(e)
            }
        });
    },[]);
    const handleClickSocialMedia = (link) =>{
        window.open(link, '_blank');
    }
    return (
        <Box
            sx={{
                backgroundImage:{xs:`url(${MenuMobileBackground})`,sm:`url(${PresentationBackground})`},
                backgroundSize: {xs:'100%',sm:'100%'},
                backgroundPosition: 'cover',
                backgroundRepeat:{xs:'repeat',sm:'repeat'}
            }}
        >
            <FooterSectionContent>
                <Box>
                    <ContainerSection width={"88%"} >
                        <Grid container columns={{xs:12,sm:12,md:12,lg:12,xl:12}}
                            sx={{
                                width:'100%'
                            }}
                            
                        >
                            <Grid  item xs={12} sm={12} md={2} lg={3} xl={3} 
                                sx={{
                                    width:'100%'
                                }}
                            >
                                <Grid  item xs={12} sm={12} md={12} lg={12} xl={10}
                                    sx={{
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:{xs:'flex-start',sm:'flex-start',md:'flex-start',lg:'center'}
                                    }}
                                >   <Box
                                        sx={{
                                            width:'100%',
                                            display:'flex',
                                            justifyContent:{xs:'center',sm:'flex-start',md:'flex-start',lg:'flex-end'}
                                        }}
                                    >
                                    <Box
                                        sx={{
                                            width:{xs:'48%',sm:'38%',md:'90%',lg:'270px'},
                                            display:'grid',
                                            mt:{xs:'0px',sm:'0px',md:'10px'},
                                            ml:{xs:'0px',sm:'0px',md:'1rem'},
                                            gridTemplateColumns:'37% 1fr',
                                            "@media (max-width: 1115px)":{
                                                gridTemplateColumns:'40% 60%',
                                            },
                                            "@media (max-width: 768px)":{
                                                gridTemplateColumns:'40% 40%',
                                            },
                                            "@media (max-width: 668px)":{
                                                gridTemplateColumns:'48% 60%',
                                            }
                                        }}
                                    >

                                        <Box>
                                            <CardMedia 
                                                component={"img"}
                                                src={ArtcryptedWhiteLogo}
                                            />
                                        </Box>

                                        <Box
                                            sx={{
                                                width:'100%',
                                                mt:'10px',
                                                display:'flex',
                                                flexDirection:'column',
                                                justifyContent:'center',
                                            }}
                                        >
                                            <CardMedia 
                                                component={"img"}
                                                src={ArtcryptedTextLogo}
                                            />

                                            <CardMedia 
                                                component={"img"}
                                                src={UniquePeopleLogo}
                                            />
                                        </Box>

                                    </Box>
                                    </Box>
                                </Grid>
                            </Grid> 
                            <Grid item xs={12} sm={4} md={4} lg={3} xl={3}
                                sx={{
                                    width:'100%',
                                    mt:{xs:'20px',sm:'0px'}
                                }}
                            >
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                        width:'100%',
                                        height:'40%',
                                        gap:'10px',
                                        margin:'auto 0'
                                    }}
                                >
                                    {socialMedia?.socialIcon?.map((option, index)=>(
                                        <Box key={index}>
                                            {!option.isLink && !option.externalLink &&
                                            <CardMedia component='img' src={option.social.asset.url} alt={option.social.asset._id} sx={{width:'35px', margin:'0 auto'}} />}
                                            {option.isLink && option.externalLink &&
                                            <Box 
                                                //key={index} 
                                                component='a'
                                                href={option.link}
                                                target='_blank'
                                                //sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                            >
                                                <CardMedia component='img' src={option.social.asset.url} alt={option.social.asset._id} sx={{width:'35px', margin:'0 auto'}} />
                                            </Box>}
                                            {option.externalLink && !option.externalLink && option.link &&
                                            <Box 
                                                //key={index} 
                                                component={Link}
                                                to={option.link}
                                                //sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                            >
                                                <CardMedia component='img' src={option.social.asset.url} alt={option.social.asset._id} sx={{width:'35px', margin:'0 auto'}} />
                                            </Box>}
                                        </Box>
                                    ))}
                                </Box>
                                <Box
                                    //display='flex'
                                    display='none'
                                    justifyContent='center'
                                    alignItems='center'
                                    sx={{
                                        width:'100%',
                                        height:'40%',
                                        gap:'10px',
                                        margin:'auto 0'
                                    }}
                                >
                                    <ImInstagram 
                                        onClick={()=>handleClickSocialMedia('https://www.instagram.com/artcrypted.io/')} 
                                        size={30} 
                                        color='#F2F2F2'
                                        style={{cursor:'pointer'}}
                                    />
                                    <ImTwitter 
                                        onClick={()=>handleClickSocialMedia('https://twitter.com/ArtCrypted')}  
                                        size={40} 
                                        color='#F2F2F2'
                                        style={{cursor:'pointer'}}
                                    />
                                    <BsYoutube 
                                        onClick={()=>handleClickSocialMedia('https://www.youtube.com/channel/UC1aQK3iJ_myY_cQMQzV6XzA')} 
                                        size={40} 
                                        color='#F2F2F2'
                                        style={{cursor:'pointer'}}
                                    />
                                    <FaDiscord 
                                        onClick={()=>handleClickSocialMedia('https://discord.com/invite/JjRJZaMeyn')} 
                                        size={40} 
                                        color='#F2F2F2'
                                        style={{cursor:'pointer'}}
                                    />
                                </Box>
                            </Grid>

                            <Grid  item xs={6} sm={4} md={4} lg={3} xl={3}
                                sx={{
                                    width:'100%',
                                    display:'flex',
                                    justifyContent:'center',
                                 
                                }}
                            >
                                <Box sx={{color:'#0D0D0D',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}}>
                                    <Box component='h3' sx={{color:'#0D0D0D',fontSize:'23px',fontWeight:'bold',m:'00px 0px'}}>{t('footer.column_1.title')}</Box>
                                    {marketOptions?.marketplaceOptions?.map((option, index)=>(
                                        <div key={index} style={{margin:'8px 0px'}} >
                                            {
                                                option.item && !option.isLink && !option.externalLink && 
                                                <Box 
                                                    sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2'}}
                                                >
                                                    {option.item}
                                                </Box>
                                            }
                                            {
                                                option.item && option.isLink && !option.externalLink &&
                                                <Box 
                                                    component={Link}
                                                    to={option.link ? option.link : "/home"}
                                                    sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                                >
                                                    {option.item}
                                                </Box>
                                            }
                                            {
                                                option.item && !option.isLink && option.externalLink &&
                                                <Box 
                                                    component='a'
                                                    href={option.link}
                                                    target='_blank'
                                                    sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                                >
                                                    {option.item}
                                                </Box>
                                            }
                                            {
                                                option.item && option.isLink && option.externalLink &&
                                                <Box 
                                                    component='a'
                                                    href={option.link}
                                                    target='_blank'
                                                    sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                                >
                                                    {option.item}
                                                </Box>
                                            }
                                        </div>
                                    ))}
                                </Box>
                                <Box sx={{color:'#0D0D0D',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif', display:'none'}}>
                                    <Box component='h1' sx={{color:'#0D0D0D',fontSize:{xs:'22px',sm:'30px'}}}>{t('footer.column_1.title')}</Box>
                                    <Box component={Link} to='/explore' sx={{textDecoration:'none',fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2', cursor:'pointer'}}>{t('footer.column_1.item_1')}</Box>
                                    <Box sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2'}}>{t('footer.column_1.item_2')}</Box>
                                    <Box sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2'}}>{t('footer.column_1.item_3')}</Box>
                                    <Box component={Link} to='/explore' sx={{textDecoration:'none', fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2', cursor:'pointer'}}>{t('footer.column_1.item_4')}</Box>
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4} md={2} lg={3} xl={3}
                                sx={{
                                    width:'100%',
                                    display:'flex',
                                    justifyContent:'center'
                                }}
                            >
                                <Box sx={{color:'#0D0D0D',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}}>
                                    <Box component='h3' sx={{color:'#0D0D0D',fontSize:'23px',fontWeight:'bold',m:'0px 0px'}}>{t('footer.column_2.title')}</Box>
                                    {legalOptions?.legalOptions?.map((option, index)=>(
                                        <div key={index} style={{margin:'8px 0px'}}>
                                            {!option.isLink && !option.externalLink &&
                                            <Box 
                                                //key={index} 
                                                sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2'}}
                                            >
                                                {option.item}
                                            </Box>}
                                            {option.isLink && option.externalLink && option.link &&
                                            <Box 
                                                //key={index} 
                                                component='a'
                                                href={option.link}
                                                target='_blank'
                                                sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                            >
                                                {option.item}
                                            </Box>}
                                            {option.isLink && !option.externalLink &&
                                            <Box 
                                                //key={index} 
                                                component={Link}
                                                to={option.link ? option.link : "/home"}
                                                sx={{fontSize:'17px',fontWeight:'300', color:'#F2F2F2', textDecoration:'none', cursor:'pointer'}}
                                            >
                                                {option.item}
                                            </Box>}
                                        </div>
                                    ))}
                                </Box>
                                {/* <Box sx={{color:'#0D0D0D',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif'}}>
                                    <Box component='h1' sx={{color:'#0D0D0D',fontSize:{xs:'22px',sm:'30px'}}}>{t('footer.column_2.title')}</Box>
                                    {legalOptions?.legalOptions?.map((option, index)=>(
                                        <Box key={index} sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2'}}>{option}</Box>
                                    ))}
                                </Box> */}
                                <Box sx={{color:'#0D0D0D',fontFamily:'Futura,Trebuchet MS,Arial,sans-serif', display:'none'}}>
                                    <Box component='h1' sx={{color:'#0D0D0D',fontSize:{xs:'22px',sm:'30px'}}}>{t('footer.column_2.title')}</Box>
                                    <Box component={Link} to='/explore' sx={{textDecoration:'none',fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2', cursor:'pointer'}}>{t('footer.column_1.item_1')}</Box>
                                    <Box sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2'}}>{t('footer.column_2.item_2')}</Box>
                                    <Box sx={{fontSize:{xs:'16px',sm:'22px'}, color:'#F2F2F2'}}>{t('footer.column_2.item_3')}</Box>
                                </Box>
                                
                            </Grid>
                        </Grid>
                    </ContainerSection>
                    <Divider sx={{width:'100%',background:'#fff',color:'#fff', marginBottom:'1rem'}} />
                    <FooterText>
                        <Box 
                            sx={{
                                color:'#0D0D0D',
                                fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                fontSize:{xs:'16px',sm:'18px',md:'20px'}
                            }}
                        >
                            © 2022 Artcrypted. All rights reserved
                        </Box>
                    </FooterText>
                </Box>
            </FooterSectionContent>
        </Box>
    );
};

export default Footer;
