import Web3 from 'web3';
import Contract from './PerseaSimpleCollection.json';

export const deploy = async(folder,price,limit,from,provider) => {
    let web3 = new Web3(provider);
    let contract = new web3.eth.Contract(Contract.abi)
    console.log(Contract)
    price = web3.utils.toWei(""+price);
    console.log(folder,price,limit,from,provider)
    return  contract.deploy({
        data :Contract.bytecode,
        arguments : [
          folder,price,limit
        ]
     }).send({
       from : web3.utils.toChecksumAddress(from)
     })
}
