
import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import Collapse from  '@mui/material/Collapse';
import Input from  '@mui/material/Input';
import AddIcon from '@mui/icons-material/Add';
import ButtonStyled from 'components/ButtonStyled'
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import styled from '@emotion/styled'
import { useNavigate} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TextField = styled('select')`
    width:100%;
    border-radius:8px;
    border:1px solid #E5E5E5;
    padding:10px;
    font-size:16px;
    font-weight:bold;
    font-family:Futura,Trebuchet MS,Arial,sans-serif;
`;


const FilterPriceRange = ({
    requestIsLoad,
    rangeBottom,
    setRangeBottom,
    rangeTop,
    setRangeTop,
    tempRangeBottom,
    tempRangeTop,
    countNfts,
    pageNftRef,
    setSliceNFT,
    setNewContent,
    resetFilter,
    changeFilter,
    setUrlNFT,
    limit,
    listNFT
    }) => {
    const { t } = useTranslation("translate");
    const history = useNavigate();
    const [range, setRange] = useState(true);


    const handleChangeRangeBottom = (e) =>{
        const {value} = e.target;
        if(Number(value) < 0){
            setRangeBottom('')
        }else{
            setRangeBottom(e.target.value)
        }       
    }

    const handleChangeRangeTop = (e) =>{
        const {value} = e.target;
        if(Number(value) < 0){
            setRangeTop('')
        }else{
            setRangeTop(value)
        }  
    }

    const handleClickSetPriceRange = () => {
        if(tempRangeBottom != rangeBottom || tempRangeTop != rangeTop){
            countNfts.current = listNFT;
            pageNftRef.current = 0;
            setSliceNFT(10)
            setNewContent([])
            resetFilter("priceRange");
            changeFilter.current = true;
            history(`/explore?limit=${limit}&page=0&order=created&PRICE_RANGE_BOTTOM=${rangeBottom}&PRICE_RANGE_TOP=${rangeTop}`)
            setUrlNFT(`${process.env.REACT_APP_URL_API}/nft?limit=${limit}&page=0&order=created&PRICE_RANGE_BOTTOM=${rangeBottom}&PRICE_RANGE_TOP=${rangeTop}&domain=${process.env.REACT_APP_DOMAIN}`)
        }
    }




    return (
        <Box
            component='article'
            display='flex'
            flexDirection='column'
            sx={{
                borderBottom:'1px solid #e0e0e0',
            }}   
        >
            <Box
                component='article'
                display='flex'
                justifyContent='space-between'
                alignItems='center'  
            >
                <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.price_range")}</h1>
                <span onClick={()=>setRange(!range)}>{!range ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}</span>
            </Box>

            <Collapse in={range} timeout="auto" unmountOnExit>
            <Box display='flex' justifyContent='flex-start' sx={{width:'95%',margin:'0 auto',gap:'10px', marginBottom:'25px'}}>
                    <Box
                        sx={{width:'30%'}}
                    >
                        <TextField 
                            id='currency'
                            name='currency'
                            as='select'
                            disabled={requestIsLoad}
                        >
                            <option value='avax'>AVAX</option>
                            <option value={'wavax'}>WAVAX</option>
                        </TextField> 
                    </Box>

                    <Input 
                        type='number' 
                        placeholder='0.00' 
                        disableUnderline
                        // value={filters && filters.length > 1 && filters[1].priceRange && filters[1].priceRange.min.keyValue && filters[1].priceRange.min.keyValue >= 0 ? filters[1].priceRange.min.keyValue : '' }
                        name="rangeMin"
                        disabled={requestIsLoad}
                        value = {rangeBottom}
                        onChange={(e)=>{handleChangeRangeBottom(e)}}
                        sx={{
                            width:'30%',
                            border: '1px solid #e0e0e0',
                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        }}
                    />
                    <Box
                        sx={{
                            display:'flex',
                            alignItems: 'center',
                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                            fontWeight:'bold',
                            fontSize:'20px'
                        }}
                    >
                        a
                    </Box>

                    <Input 
                        type='number' 
                        placeholder='0.00' 
                        //value={filters && filters.length > 1 && filters[1].priceRange && filters[1].priceRange.max.keyValue && filters[1].priceRange.max.keyValue >= 0 ? filters[1].priceRange.max.keyValue : '' }
                        disableUnderline
                        name="rangeMax"
                        disabled={requestIsLoad}
                        value = {rangeTop}
                        onChange={(e)=>{handleChangeRangeTop(e)}}
                        sx={{
                            width:'30%',
                            border: '1px solid #e0e0e0',
                            fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                        }}
                    />
                </Box>
                <Box
                    sx={{width:'95%',margin:'0 auto',marginBottom:'15px'}}
                >
                    <ButtonStyled
                        type='submit'
                        onClick={()=>handleClickSetPriceRange()}
                        isDisabled={Number(rangeBottom) <= 0 || rangeBottom == '' || Number(rangeTop) <= 0 && rangeTop == ''|| requestIsLoad}
                        text={t('sidebar_component.set_price')}
                        width='100%'
                    />
                </Box>
            </Collapse>
        </Box>
    );
};

FilterPriceRange.propTypes = {
    requestIsLoad: PropTypes.bool,
    rangeBottom: PropTypes.number,
    setRangeBottom:PropTypes.func,
    rangeTop: PropTypes.number,
    setRangeTop: PropTypes.func,
    tempRangeBottom: PropTypes.number,
    setTempRangeBottom: PropTypes.func,
    tempRangeTop: PropTypes.number,
    setTempRangeTop: PropTypes.func,
    countNfts: PropTypes.object,
    pageNftRef: PropTypes.object,
    setSliceNFT: PropTypes.func,
    setNewContent: PropTypes.func,
    resetFilter: PropTypes.func,
    changeFilter: PropTypes.object,
    setUrlNFT: PropTypes.func,
    limit: PropTypes.number,
    listNFT: PropTypes.number
};

export default FilterPriceRange;