import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const AccountLink = styled(Link)`
    text-decoration: none;
    color: #666;
    font-family: Courier, "Lucida Console", monospace;
    cursor: pointer;
`;
export const AccountLinkImage = styled(Link)`
    text-decoration: none;
    color: #666;
    font-family: Courier, "Lucida Console", monospace;
    cursor: pointer;
`;
export const ContractLink = styled.a`
    text-decoration: none;
    color: #666;
    font-family: Courier, "Lucida Console", monospace;
    cursor: pointer;
`;