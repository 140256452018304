import React from 'react';
import PropTypes from 'prop-types';
import {Box, Avatar,Card,CardMedia, Tooltip} from '@mui/material';
import { Link } from 'react-router-dom';
import styled from "@emotion/styled/macro";
import { getThumbnail } from 'services/Thumbail/getThumbnail';

export const DisplayOver = styled(Box)({
    borderRadius: '8px',
    height: "100%",
    left: "0",
    position: "absolute",
    top: "0",
    width: "100%",
    zIndex: 2,
    transition: "background-color 350ms ease",
    backgroundColor: "transparent",
    padding: "20px 20px 0 20px",
    boxSizing: "border-box",
  });

export const DisplayOverBottom = styled(Box)({
    borderRadius: '8px',
    bottom: "0",
    left: "0",
    position: "absolute",
    width: "100%",
    zIndex: 2,
    transition: "background-color 350ms ease",
    backgroundColor: "transparent",
    padding: "15px",
    boxSizing: "border-box",
});
  
export const BigTitle = styled(Box)({
    //textTransform: "uppercase",
    fontSize: "30px",
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: '18rem',
    display:'inline-block',
    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
    ["@media screen and (max-width: 400px)"]: {
      fontSize: "15px"
    }
});

export const Hover = styled(Box)({
    borderRadius: '8px',
    opacity: 0,
    transition: "opacity 350ms ease",
  });

export const SubTitle = styled.h4({
    fontFamily: "Helvetica",
    transform: "translate3d(0,50px,0)",
    transition: "transform 350ms ease",
  });
  
export const Paragraph = styled.p({
    transform: "translate3d(0,50px,0)",
    transition: "transform 350ms ease",
  });
  
export const CTA = styled(Link)({
    position: "absolute",
    bottom: "20px",
    left: "20px",
  });

export const Background = styled(Card)({
    borderRadius: '10px 10px',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    position: "relative",
    width:'100%',
    height:'100%',
    cursor: "pointer",
    //backgroundOrigin: "url(https://assets.foundation.app/2Z/Pu/QmdoxFGWDa6Pcygj9hxfcJtXXvwWxFpWNeDUJXPPGA2ZPu/nft_preview.mp4)",
    //backgroundImage: "url(https://f8n-production.imgix.net/collections/mv9wjhxzq-Backyard%20Diaries%20Vol.1%20%2302.jpg?q=50&auto=format%2Ccompress&fit=fill&max-w=800&max-h=800&exp=-5)",
    // Other background code
    [`:hover ${DisplayOver}`]: {
      backgroundColor: "rgba(0,0,0,.5)",
    },
    [`:hover ${SubTitle}, :hover ${Paragraph}`]: {
      transform: "translate3d(0,0,0)",
    },
    [`:hover ${Hover}`]: {
      opacity: 1,
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        //transform: 'translateY(-2px)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        /* select class */
        '& .card-collection': {
          transform: 'scale(1.06)',
        },
        '& .is-video-collection': {
          transform: 'scale(3.08)',
        }
    }
});


export const CardContentCustom = styled(Card)({
    borderRadius: '8px 8px 0px 0px',
    background: 'transparent',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    padding:'none',
    position: "relative",
    width:'100%',
    height:'100%',
    cursor: "pointer",
    boxShadow: "none",
  //  border: "1px solid #e3e3e3",
    [`:hover ${CardMedia}`]: {
      backgroundColor: "rgba(0,0,0,.5)",
    },
  
    [`:hover ${CardMedia}`]: {
      opacity: 1,
    },
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        //transform: 'translateY(-2px)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        /* select class */
        '& .card-collection': {
          transform: 'scale(1.06)',
        },
        '& .is-video-collection': {
          transform: 'scale(3.08)',
        }
    },
    ["@media (max-width: 320px)"]: {
      height: '330px'
    }
});


const CardCollection =  ({item,width}) => {
    return (
        <React.Fragment>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    mt:1,
                    mb:1
                }}
            >
                <Box component={Card} 
                    sx={{ 
                        width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                        //marginLeft:{xs:'0px',sm:'0px',md:'-20px'},
                        "@media screen and (min-width: 2880px)":{
                        width:`${Number( (width / 5) - 60)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px'
                        }
                    }}
                >
                    <CardContentCustom
                        sx={{          
                        width:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                        height:{xs:'90vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                        "@media screen and (min-width: 2880px)":{
                            width:`${Number( (width / 5) - 60)}px`,
                            height:`${Number( (width / 5) - 60)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px',
                            height:'490px'
                        },
                        }}
                    >
                        <Link 
                            to={`/collection-buy?address=${item.project_key}`}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            {
                            item && item.is_video  ?
                            <CardMedia
                                className={'card-collection'}
                                component={'video'}
                                src={item && item.thumb_url}
                                autoPlay
                                loop
                                muted
                                sx={{
                                    position:'relative',
                                    borderRadius: '8px',
                                    height:'100%',
                                    width:'100%',
                                    margin:'0 auto',
                                    transform: 'scale(3)'
                                }}
                            />
                            :
                            <CardMedia
                                className={'card-collection'}
                                component={'img'}
                                src={ (item.thumbnails) ? getThumbnail(item.thumbnails, 650,500) : item.thumb_url}
                                sx={{
                                    position:'relative',
                                    borderRadius: '10px 10px 0 0',
                                    height:'100%',
                                    width:'100%',
                                    margin: '0 auto'
                                }}
                            />
                            }
                        </Link>
                        <DisplayOverBottom>
                            <Box
                                className="notranslate"
                                sx={{
                                    padding:'15px',
                                    fontFamily:'Futura,Trebuchet MS,Arial,sans-serif ',
                                    display:'flex',
                                    flexDirection:'column',
                                }}
                            >
                                <Box
                                    sx={{
                                        width:'100%',
                                        display:'flex',
                                        justifyContent:'flex-start'
                                    }}
                                >
                                    <Tooltip title={item.name}  placement="top">
                                        <Box
                                            sx={{
                                                width:'auto',
                                                boxSizing:'border-box',
                                                maxWidth:{xs:'100%',sm:'100%',md:'100%',lg:'60%',xl:'60%'},
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    boxSizing:'border-box',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    fontWeight:600, 
                                                    fontSize:'30px',
                                                    backgroundColor:'rgba(0, 0, 0, 0.2)',
                                                    padding:'0.5rem',
                                                    borderRadius: '10px',
                                                }}
                                            >
                                                {item.name}
                                            </Box>
                                        </Box>
                                    </Tooltip>
                                </Box> 
                                <Box
                                    sx={{
                                        width:'100%',
                                        mt:'8px',
                                        display:'flex',
                                        justifyContent:'flex-start'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:'auto',
                                            boxSizing:'border-box',
                                            maxWidth:{xs:'100%',sm:'100%',md:'100%',lg:'60%',xl:'60%'},
                                            display:'flex',
                                            justifyContent:'flex-start',
                                            backgroundColor:'rgba(0, 0, 0, 0.2)',
                                            padding:'0.5rem',
                                            borderRadius: '999px',
                                            gap:'0.5rem',
                                        }}
                                    >
                                        <Avatar variant='circular' src={item.user.profile_pic_url} />
                                        <Box
                                            sx={{
                                                width:'100%',
                                                boxSizing:'border-box',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                fontWeight:600, 
                                                fontSize:'30px'
                                            }}
                                        >
                                            {
                                                item && item.user && item.user.username ? item.user.username
                                                :
                                                item && item.owner && String(item.owner ).substring(0,5)+ '...' + String(item.owner).substring(38,54)
                                            }
                                        </Box>
                                    </Box>
                                </Box> 
                            </Box>
                        </DisplayOverBottom>
                    </CardContentCustom>
                </Box>
            </Box>
        </React.Fragment>
    );
};

CardCollection.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardCollection;