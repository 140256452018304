export const  nameAndSymbol = (str) => {
    let words = str.split(" ");
    console.log('words ::', words)
    let symbol = "";
    let name = "";
    for (let value of words) {
        const element = value;
        symbol += element['0'];
    }
    name = symbol
    return { name, symbol }
}