import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box,Card, CardContent,Divider} from "@mui/material";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from "@emotion/styled/macro";

export const CardContentCustom = styled(Card)({
    borderRadius: '8px 8px 0px 0px',
    background: 'transparent',
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    color: "#FFF",
    padding:'none',
    position: "relative",
    width:'100%',
    height:'100%',
    boxShadow: "none",
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        //transform: 'translateY(-2px)',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        /* select class */
        '& .card-collection': {
          transform: 'scale(1.06)',
        },
        '& .is-video-collection': {
          transform: 'scale(3.08)',
        }
    },
    ["@media (max-width: 320px)"]: {
      height: '330px'
    }
});

const CardProfile =  ({item,width}) => {
    const { t } = useTranslation("translate")
    return (
        <React.Fragment>
            <Box
                sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'center',
                    mt:1,
                    mb:1
                }}
            >
                <Box component={Card} 
                    sx={{ 
                        width:{xs:'88vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                        //marginLeft:{xs:'0px',sm:'0px',md:'-20px'},
                        "@media screen and (min-width: 2880px)":{
                        width:`${Number( (width / 5) - 60)}px`
                        },
                        "@media screen and (min-width: 3580px)":{
                            width:'490px'
                        }
                    }}
                >
                    <CardContentCustom
                        sx={{          
                            width:{xs:'88vw',sm:`${Number( (width / 2) - 20)}px`,md:`${Number( (width / 3) - 60)}px`,lg:`${Number( (width / 3) - 60)}px`,xl:`${Number( (width / 3) - 60)}px`},
                            height:{xs:'88vw',sm:`${Number( (width / 2) + 20)}px`,md:`370px`},
                            "@media screen and (min-width: 3580px)":{
                                width:'490px'
                            }
                        }}
                    >
                        <Link 
                            to={`/profile?address=${item.main_key}`}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Box
                                draggable={false}
                                sx={{
                                    width:'100%',
                                    minHeight:'50%',
                                    maxHeight:'50%',
                                    backgroundImage: `url(${item && item.banner_url && item.banner_url})`,
                                    backgroundSize:'cover',
                                    backgroundPosition:'center',
                                    borderRadius:'10px 10px 0px 0px',
                                    position:'relative'
                                }}
                            >
                                <Box
                                    sx={{
                                        position:'absolute',
                                        left:'20px',
                                        bottom:'-25px'
                                    }}
                                >
                                    <Box
                                        sx={{
                                            border:'2px solid #fff',
                                            borderRadius:'50% 50%',
                                        }}
                                    >
                                        <Link 
                                            to={`/profile?address=${item.main_key}`}
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                        >
                                        <Avatar 
                                            alt="user" 
                                            src={item && item.profile_pic_url && item.profile_pic_url}
                                            sx={{
                                                width:'70px',
                                                height:'70px'
                                            }}
                                        />
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                        </Link>
                        <br/>
                        <Box
                            sx={{
                            p:1
                            }}
                        >
                            <Box
                                sx={{
                                    display:'flex',
                                    justifyContent:'flex-start',
                                    p:'0px 10px'
                                }}
                            >   
                            { item.username  &&
                                <Box
                                    component={Link}
                                    to={`/profile?address=${item.main_key}`}
                                    sx={{
                                        width:'auto',
                                        boxSizing:'border-box',
                                        maxWidth:{xs:'90%',sm:'90%',md:'90%',lg:'90%',xl:'80%'},
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Box
                                        className="notranslate"
                                        sx={{
                                            width:'100%',
                                            boxSizing:'border-box',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            fontWeight:600, 
                                            fontSize:'28px',
                                            borderRadius: '10px',
                                            background: 'linear-gradient(110.78deg, #76E650 -1.13%, #F9D649 15.22%, #F08E35 32.09%, #EC5157 48.96%, #FF18BD 67.94%, #1A4BFF 85.34%, #62D8F9 99.57%)',
                                            backgroundClip: 'text',
                                            WebkitTextFillColor: 'transparent'
                                        }}
                                    >
                                        {
                                        item && item.username != item.username.substring(0,12)+'com' ? '@'+item.username
                                        :
                                        item && item.username && '@'+(item.username).substring(0,5)+ '...' +(item.username).substring(38,54)
                                        }
                                    </Box>
                                </Box>
                            }
                            </Box>
                            <Divider />
                            <CardContent
                                sx={{
                                    display:'flex',
                                    justifyContent:'space-between',
                                }}
                            >
                                <Box>
                                    <Box
                                        className="notranslate"
                                        sx={{
                                            color:'#000',
                                            fontSize:'24px',
                                            fontWeight: 600,
                                            letterSpacing:'-0.01em',
                                            fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        }}
                                    >
                                    {item != null && item.followers ? item.followers:0}
                                    </Box>
                                    <Box
                                        sx={{
                                            color:'#666',
                                            fontSize:'18px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("card_profile.followers")}
                                    </Box>
                                </Box>
                                {/* <Divider orientation="vertical" flexItem /> */}
                                <Box>
                                    <Box
                                        className="notranslate"
                                        sx={{
                                            color:'#000',
                                            fontSize:'24px',
                                            fontWeight: 600,
                                            letterSpacing:'-0.01em',
                                            fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                        }}
                                    >
                                        {item != null && item.nfts ? item.nfts:0}
                                    </Box>
                                    <Box
                                        sx={{
                                            color:'#666',
                                            fontSize:'18px',
                                            fontWeight: 600,
                                        }}
                                    >
                                        NFTs
                                    </Box>
                                </Box>

                            </CardContent>
                            
                        </Box>
                    </CardContentCustom>
                </Box>
            </Box>
        </React.Fragment>
    );
};

CardProfile.propTypes = {
    item: PropTypes.object,
    width: PropTypes.number,
    index: PropTypes.number
};

export default CardProfile;