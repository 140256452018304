import React from 'react';
import Routes from './Routes';
import Page from './components/Page';
import WalletProvider from 'hooks/WalletContext';
import StatusTxContext from 'hooks/StatusTxContext';
import {I18nextProvider} from 'react-i18next'
import i18n from './i18n';
import DrawerMobileProvider from 'hooks/DrawerMobileContext';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'aos/dist/aos.css';
import './App.css';

const App = () => {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };
  React.useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <DrawerMobileProvider>
        <WalletProvider> 
          <StatusTxContext>
            <Page>
                <Routes />
            </Page>
          </StatusTxContext>
        </WalletProvider>
      </DrawerMobileProvider>
    </I18nextProvider>
  );
};

export default App;
