import React from 'react'
import { Box ,Collapse} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import SortNFTs from './components/SortNFTs/SortNFTs';
import SortProfile from './components/SortProfile';
import { useTranslation } from 'react-i18next';
import SortCollection from './components/SortCollection';
import PropTypes from 'prop-types';
import ListIcon from '@mui/icons-material/List';

const SideBar = ({
    wallet,
    urlProject,
    isNFT,
    isCollection,
    openFilters,
    setOpenFilters,
    urlNFT,
    setUrlMyNFT,
    typeFilter,
    setTypeFilter,
    loadingNFT,
    limitNFTRef,
    listNFT,
    setNewsNFTs,
    setSliceNFT,
    setNewsMyNFT,
    setSliceMyNFT,
    requestIsLoad,
    requestIsLoadOwner,
    countNfts,
    pageNftRef,
    countMyNFT,
    pageMyNFTRef,
    isProfile,
    rangeBottom,
    setRangeBottom,
    rangeTop,
    setRangeTop,
    changeFilter,
    ownerNFTUrl,
    setOwnerNFTUrl,
    tabActive,
    setSelectedFilter,
    categoriesString,
    setCategoriesString
    }) => {
    const { t } = useTranslation("translate");
    
    return (
        <Box
            sx={{
                p:'0px 10px'
            }}
        >
            <Box 
                component='aside'
                sx={{
                    boxSizing:'border-box',
                    width:openFilters?{xs:'100%',sm:'320px'}:'30px',
                    top: '0px',
                    left: '0px',
                    '@media screen and (max-width: 750px)': {
                        position:'static',
                        width:'100%'
                    }
                }}
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={{
                        display:openFilters?'none':'flex',
                        marginTop:'10px',
                        marginLeft:'10px',
                        borderBottom:'1px solid #e0e0e0'
                    }}   
                >
                    <ListIcon 
                        sx={{
                            fontSize:'30px',
                            cursor:'pointer'
                        }}  
                        onClick={()=>setOpenFilters(!openFilters)}
                    />
                </Box>
                <Collapse in={openFilters} timeout="auto" unmountOnExit orientation={'vertical'} >
                <Box
                        component='article'
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            display:'flex',
                            boxSizing:'border-box',
                            justifyContent:'space-between',
                            cursor:'pointer',
                            width:{xs:'100%',sm:'100%',md:'320px'},
                            '@media screen and (max-width: 758px)':{
                                display:'flex',
                            }
                        }}   
                    >
                        <h1 >{t('sidebar_component.filters')}</h1>
                        <span><ClearIcon  onClick={()=>setOpenFilters(!openFilters)} /></span>
                    </Box>
                    {isNFT && 
                        <SortNFTs 
                            wallet={wallet} 
                            urlNFT={setUrlMyNFT}
                            setUrlMyNFT={setUrlMyNFT}
                            typeFilter={typeFilter}
                            setTypeFilter={setTypeFilter} 
                            loadingNFT={loadingNFT}
                            limitNFTRef={limitNFTRef}
                            listNFT={listNFT}
                            setNewsNFTs={setNewsNFTs}
                            setSliceNFT={setSliceNFT}
                            setNewsMyNFT={setNewsMyNFT}
                            setSliceMyNFT={setSliceMyNFT}
                            requestIsLoad={requestIsLoad}
                            requestIsLoadOwner={requestIsLoadOwner}
                            countNfts={countNfts}
                            pageNftRef={pageNftRef}
                            countMyNFT={countMyNFT}
                            pageMyNFTRef={pageMyNFTRef}
                            rangeBottom={rangeBottom}
                            setRangeBottom={setRangeBottom}
                            rangeTop={rangeTop}
                            setRangeTop={setRangeTop}
                            changeFilter={changeFilter}
                            ownerNFTUrl={ownerNFTUrl}
                            setOwnerNFTUrl={setOwnerNFTUrl}
                            tabActive={tabActive}
                            setSelectedFilter={setSelectedFilter}
                            urlNFTString={urlNFT}
                            categoriesString={categoriesString}
                            setCategoriesString={setCategoriesString}
                        />}
                    {isCollection && <SortCollection urlProject={urlProject} wallet={wallet} />}
                    {isProfile && <SortProfile />}
                </Collapse>
            </Box>
        </Box>
    )
}

SideBar.defaultProps = {
    wallet:'',
    urlProject:()=>{},
    isNFT:true,
    isCollection:false,
    openFilters:true,
    setOpenFilters:()=>{},
    urlNFT:'',
    setUrlMyNFT:()=>{},
    typeFilter:'',
    setTypeFilter:()=>{},
    loadingNFT:false,
    limitNFTRef:{current:10},
    listNFT:10,
    setNewsNFTs:()=>{},
    setSliceNFT:()=>{},
    setNewsMyNFT: ()=>{},
    setSliceMyNFT: ()=>{},
    requestIsLoad:false,
    requestIsLoadOwner:false,
    countNfts:{current:4},
    pageNftRef:{current:0},
    countMyNFT:{current:4},
    pageMyNFTRef:{current:0},
    isProfile:false,
    rangeBottom:0,
    setRangeBottom: ()=>{},
    rangeTop:0,
    setRangeTop: ()=>{},
    changeFilter: {current:''},
    ownerNFTUrl:'',
    setOwnerNFTUrl: ()=>{},
    tabActive: {current:'nft'},
    setSelectedFilter: ()=>{},
    categoriesString: '',
    setCategoriesString: ()=>{}
}

SideBar.propTypes = {
    wallet:PropTypes.string,
    urlProject:PropTypes.func,
    isNFT:PropTypes.bool,
    isCollection:PropTypes.bool,
    openFilters:PropTypes.bool,
    setOpenFilters:PropTypes.func,
    urlNFT: PropTypes.string,
    setUrlMyNFT:PropTypes.func,
    typeFilter:PropTypes.string,
    setTypeFilter:PropTypes.func,
    loadingNFT:PropTypes.bool,
    limitNFTRef:PropTypes.object,
    listNFT:PropTypes.number,
    setNewsNFTs:PropTypes.func,
    setSliceNFT:PropTypes.func,
    setNewsMyNFT: PropTypes.func,
    setSliceMyNFT: PropTypes.func,
    requestIsLoad:PropTypes.bool,
    requestIsLoadOwner: PropTypes.bool,
    countNfts:PropTypes.object,
    pageNftRef:PropTypes.object,
    countMyNFT: PropTypes.object,
    pageMyNFTRef: PropTypes.object,
    isProfile: PropTypes.bool,
    rangeBottom: PropTypes.number,
    setRangeBottom:PropTypes.func,
    rangeTop: PropTypes.number,
    setRangeTop: PropTypes.func,
    changeFilter: PropTypes.object,
    ownerNFTUrl : PropTypes.string,
    setOwnerNFTUrl: PropTypes.func,
    tabActive: PropTypes.object,
    setSelectedFilter: PropTypes.func,
    categoriesString: PropTypes.string,
    setCategoriesString: PropTypes.func
}

export default SideBar