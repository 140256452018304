export let autorizedAddress = [
    '0x30e6fc29D2C1dF526F12D2Be50B828E2aC0641f3',
    '0xfc33B55E0457E9c3965666c459D7C7F042278d10', 
    '0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014',
    '0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014',
    '0xa5461cbCf9c767264CC619bCF1AF3AaD083A5b22',
    '0xC3ad50db2c145DE41793C02Ba19cCf328b15A642',
    '0x59efC1182b1E4bb642a34ad34223c2E1EaEc6D5A',
    '0x2273b2cdCC5963D2E7756112412e7f25f59Bed3A',
    '0xc8b84a86cF9af59eBceEFC8B0ccaCf529E241585',
    '0xEea1B9CDb87576A26B01FA782ffb30D8814B8d97'
  ]