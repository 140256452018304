import React from 'react'
import { Avatar, Box, Button, Card, CardContent, CardMedia, Container, Grid } from '@mui/material'
import { profiles } from 'mocks/profiles'
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ProfileCard = ({showBtnAll, content, limit,setNewRequest,infinityScroll}) => {
    const { t } = useTranslation("translate");
    let section = 'profileCards';
    let allCardsProfile = document.querySelectorAll("#profileCards .profileCard");
    let ultimo = null;
    console.log('content ::',content);
    let observerProfile = new IntersectionObserver((cards)=>{
        cards.forEach((card)=>{
            if(card.isIntersecting){
                observerProfile.unobserve(ultimo)
                infinityScroll();
            }
        })
    },
    {
        rootMargin:'0px 0px 10px 0px',
        threshold:1.0
    })
    
    React.useEffect(()=>{
        allCardsProfile = document.querySelectorAll("#profileCards .profileCard");
        if(allCardsProfile && allCardsProfile.length > 0){
            ultimo = allCardsProfile[allCardsProfile.length-1];
            setNewRequest(false)
            observerProfile.observe(ultimo)        
        }
    },[allCardsProfile])



    return <>
        <Box sx={{marginTop:'1.5rem'}}>
            <Grid 
                container 
                columns={{xs:12,sm:12, md:12, lg:12, xl:12}}
                rowSpacing={"20px"} 
                spacing={"20px"}
                id={section}
            >
                {content?.slice(0, limit).map((item, index)=>{
                    return  <Grid 
                        key={index} 
                        item 
                        xs={12}
                        sm={6} 
                        md={6} 
                        lg={3} 
                        xl={3}
                        sx={{
                            width:'100%',
                            minHeight:'400px'
                        }}
                        className={"profileCard"}
                    >
                        <Link 
                            to={`/profile?address=${item.wallet}`}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Card
                                sx={{
                                    width:'100%',
                                    borderRadius:'8px',
                                    cursor:'pointer',
                                    transition: 'all 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    src={item && item.banner_url}
                                    alt="art"
                                    autoPlay
                                    loop
                                    muted
                                    sx={{
                                        borderRadius: '8px 8px 0 0',
                                        height: {xs:'180px',sm:'180px',md:'180px',lg:'180px',xl:'180px'},
                                        width:'100%',
                                        margin: '0 auto',
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display:'flex',
                                        flexDirection:'column',
                                        marginBottom:'-28px',
                                    }}
                                >
                                    <Box>
                                        <Avatar 
                                            src={item && item.profile_pic_url}
                                            sx={{
                                                marginTop:'-40px',
                                                border: '4px solid #fff',
                                                width: 65, 
                                                height: 65
                                            }}
                                        />  
                                    </Box>
                                    <Box
                                        sx={{
                                            display:'flex',
                                            justifyContent:'flex-start',
                                        }}
                                    >   
                                        <Box
                                            component={Link}
                                            to={`/profile?address=${item.wallet}`}
                                            sx={{
                                                width:'auto',
                                                boxSizing:'border-box',
                                                maxWidth:{xs:'90%',sm:'90%',md:'90%',lg:'90%',xl:'80%'},
                                                textDecoration: 'none'
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    width:'100%',
                                                    boxSizing:'border-box',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    fontWeight:600, 
                                                    fontSize:'24px',
                                                    borderRadius: '10px',
                                                    background: 'linear-gradient(110.78deg, #76E650 -1.13%, #F9D649 15.22%, #F08E35 32.09%, #EC5157 48.96%, #FF18BD 67.94%, #1A4BFF 85.34%, #62D8F9 99.57%)',
                                                    backgroundClip: 'text',
                                                    WebkitTextFillColor: 'transparent'
                                                }}
                                            >
                                                {
                                                item && item.username != item.username.substring(0,12)+'com' ? '@'+item.username
                                                :
                                                item && item.username && '@'+(item.username).substring(0,5)+ '...' +(item.username).substring(38,54)
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                                <Box
                                    display='flex'
                                    justifyContent='space-between'
                                    sx={{
                                        marginTop:'20px',
                                        borderTop:'1px solid #e0e0e0',
                                    }}
                                >
                                    <CardContent>
                                        <Box
                                            sx={{
                                                color:'#000',
                                                fontSize:'24px',
                                                fontWeight: 600,
                                                letterSpacing:'-0.01em',
                                                fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                            }}
                                        >
                                        {item.followers}
                                        </Box>
                                        <Box
                                            sx={{
                                                color:'#666',
                                                fontSize:'18px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            {t("cards.profile.follower_text")}
                                        </Box>
                                    </CardContent>
                                    <CardContent>
                                        <Box
                                            sx={{
                                                color:'#000',
                                                fontSize:'24px',
                                                fontWeight: 600,
                                                letterSpacing:'-0.01em',
                                                fontFamily: '"Suisse", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
                                            }}
                                        >
                                        {item.nfts}
                                        </Box>
                                        <Box
                                            sx={{
                                                color:'#666',
                                                fontSize:'18px',
                                                fontWeight: 600,
                                            }}
                                        >
                                            NFTs
                                        </Box>
                                    </CardContent>
                                </Box>
                            </Card>
                        </Link>
                    </Grid>
                }
                )}
            </Grid>
        </Box>
        {showBtnAll &&
        (<Container 
            maxWidth='sm' 
            sx={{
                display: 'flex',
                marginTop:'2rem',
                justifyContent:'center',
            }}
        >
            <Button
                variant="outlined"
                type="button"
                sx={{
                    borderRadius:'9999px',
                    backgroundColor:'#fff',
                    color:'#000',
                    border:'1px solid #e3e3e3',
                    fontSize:'18px',
                    '&:hover':{
                        backgroundColor:'#000',
                        transition:'background-color 0.3s ease-in-out',
                        color:'#fff',
                        border:'1px solid #000',
                    }
                }}
            >
                View all Profiles
            </Button>
        </Container>)}
    </>
}

ProfileCard.defaultProps = {
    showBtnAll: false,
    content: profiles,
    limit: 24,
    infinityScroll: ()=>{console.log("");},
    setNewRequest: ()=>{console.log("");}
}

ProfileCard.propTypes = {
    showBtnAll: PropTypes.bool,
    content: PropTypes.array,
    limit: PropTypes.number,
    infinityScroll: PropTypes.func,
    setNewRequest: PropTypes.func
}

export default ProfileCard