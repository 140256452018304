import React, { useEffect, useState } from 'react';
import Presentation from './components/Presentation';
import HomeBlockSanity from './components/HomeBlockSanity';
import { useFetch } from 'hooks/useFetch';
import ErrorMessage from 'components/ErrorMessage';
import Box from '@mui/material/Box';
import LoaderHero from './components/Loaders/LoaderHero';
import './components/Presentation/Presentation.css';
import ContainerSection from 'components/ContainerSection';
import BlockScroll from './components/BlockScroll';
import PresentationBackground from 'assets/background/newBackground.png';
import Categories from './components/Categories';
import MenuMobileBackground from 'assets/background/MenuMobile.png';

const Home = () => {
    const url = process.env.REACT_APP_URL_API+'/section?domain='+process.env.REACT_APP_DOMAIN
    const {data:section, loading:sectionLoading, error:sectionError} = useFetch(url);
    const [sections, setSections] = useState([])
    const [randoms,setRandoms] = useState([])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if(section != null && section.sections) {
            let newSections = []
            section.sections.forEach(item => {
                newSections.push(item)
            })
            setSections(newSections)
        }
        if(section != null && section.randoms){
            setRandoms(section.randoms)
        }
    },[section]);

    return (
        <Box>
            <Box
                sx={{
                    width:'100%',
                    height:'100%',
                    position:'relative',
                    backgroundImage: `url(${PresentationBackground})`,
                    backgroundSize: {xs:'contain',sm:'100%',md:'100%',lg:'100%',xl:'100%'},
                    backgroundPosition: 'cover',
                    backgroundRepeat:{xs:'repeat',sm:'repeat',md:'repeat',lg:'repeat',xl:'repeat'}
                }}
            >   
                <Box
                    sx={{
                        width:'100%',
                        display:'flex',
                        justifyContent:'center',
                        alignItems:'center',
                        height:{xs:'100%',sm:'100%',md:'100%',lg:'700px',xl:'900px'},               
                    }}
                >
                    {sectionLoading && <LoaderHero />}
                    {sectionError && <Box sx={{width:'100vw',display:'flex',justifyContent:'center'}} ><ErrorMessage error={sectionError.message} textColor="#fff" /></Box>}
                    {sections != null && !sectionLoading && !sectionError &&
                        <Presentation content={randoms}  /> 
                    }
                </Box>
            </Box>
            {sectionLoading && <LoaderHero />}
            {sectionError && <Box sx={{width:'100vw',display:'flex',justifyContent:'center'}} ><ErrorMessage error={sectionError.message} /></Box>}
            {sections != null && !sectionLoading && !sectionError &&
                sections.map((item,index)=>{
                    return (
                        <React.Fragment key={index}>
                            {
                                index == 2 &&
                                <Box
                                    sx={{
                                        position:'relative',
                                        width:'100%',
                                        height:{xs:'160px',sm:'160px'},
                                        backgroundImage: `url(${MenuMobileBackground})`,
                                        backgroundSize: '100%',
                                        backgroundPosition: 'cover',
                                        backgroundRepeat:'repeat',
                                        //background:'linear-gradient(90deg, rgba(55, 30, 217, 1) 0%, rgba(62, 12, 242, 1) 3%, rgba(243, 242, 242, 1) 49%, rgba(242, 242, 242, 1) 51%, rgba(166, 89, 217, 1) 94%, rgba(166, 48, 217, 1) 100%)'
                                    }}
                                    key={'L'+index}
                                >
                                    <Box
                                        sx={{
                                            width:'100%',
                                            height:'100%',
                                            display:'flex',
                                            alignItems:'center',
                                            justifyContent:'center',
                                        }}
                                    >
                                        <HomeBlockSanity />
                                    </Box>
                                </Box>
                            }
                            {
                                item && item.type.toUpperCase().includes('CATEGORIES')  ?
                                <ContainerSection width={"88%"} key={'Z'+index}>
                                    <Categories title="Categories" />
                                </ContainerSection>
                                :
                                <ContainerSection width={"88%"} key={'Y'+index}>
                                    {item && item.content && item.content.length > 0 && 
                                        <BlockScroll 
                                            content={item.content}
                                            title={item.title_en}
                                            type={item.type}
                                            index = {index}
                                            sectionLoading={sectionLoading}
                                            query={item.query}
                                        />
                                    }
                                </ContainerSection>

                            }
                        </React.Fragment>
                    )
                })
            }
        </Box>
    );
}

export default Home;
