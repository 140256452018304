import React from 'react'
import { Container } from '@mui/material'

const ServerError = () => {
  return (
    <Container 
      sx={{
        color:'#A658D8'
      }}
    >
      ServerError
    </Container>
  )
}

export default ServerError