import React from "react";
import Carousel from "react-multi-carousel";
import PropTypes from "prop-types";
import CardAuction from "./components/CardAuction";
import CardNFT from "./components/CardNFT";
import CardProfile from "./components/CardProfile";
import CardCategories from "./components/CardCategories";
import CardCurators from "./components/CardCurators";
import CardCollection from "./components/CardCollection";
import CardCollageSixImg from "./components/CardCollageSixImg";
import Box from '@mui/material/Box';

const ButtonGroup = ({ next, previous,typeCard,...rest}) => {
  const { carouselState: { currentSlide,deviceType,totalItems,slidesToShow}} = rest;

  const [show,setShow] = React.useState(false);
  const [showBack,setShowBack] = React.useState(false);
  const [showNext,setShowNext] = React.useState(false);
  
  React.useEffect(()=>{
      if((deviceType =='mobile' || deviceType =='tablet')){
        setShow(false)
      }else{
        setShow(true)
      }
  },[deviceType])

  const returnCurrent = (current) => {
    if(totalItems > slidesToShow){
      if(currentSlide > 0){
        setShowBack(true)
      }else{
        setShowBack(false)
      }
      if(currentSlide < current){
        setShowNext(true)
      }else{
        setShowNext(false)
      }
    }
  }

  const checkDevice = (deviceType,typeCard) => {
    if(typeCard != 'categories'){
      if(deviceType == "desktop"){
        returnCurrent(4);
      }else
      if(deviceType == "big"){
        returnCurrent(2);
      }
    }else{
      if(currentSlide > 0){
        setShowBack(true)
      }else{
        setShowBack(false)
      }
      if(currentSlide < (totalItems - slidesToShow)){
        setShowNext(true)
      }else{
        setShowNext(false)
      }
    }
    
  }

  React.useEffect(()=>{
    checkDevice(deviceType,typeCard);
  },[currentSlide,totalItems])

  return (
    <React.Fragment>
      {show ?
      <React.Fragment>
        {
          showBack &&
          <Box
            onClick={() => previous()}
            draggable="false"
            sx={{
              position: "absolute",
              top:'0px',
              left: "0px",
              width: "30px",
              height: "100%",
              backgroundColor: "transparent",
              fontSize: "60px",
              color: "rgb(168,88,216)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "10px 0px 0px 10px"
            }}
          >
            <Box
              sx={{
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                KhtmlUserSelect: 'none',
                msUserSelect:'none'
              }}
            >
              {"<"}
            </Box>
          </Box>
        }
        {
          showNext &&
          <Box
            onClick={() => next()}
            sx={{
              position: "absolute",
              top:'0px',
              right: "0px",
              width: "30px",
              height: "100%",
              backgroundColor: "transparent",
              fontSize: "60px",
              color: "rgb(168,88,216)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: "0px 10px 10px 0px"
            }}
          >
            <Box
              sx={{
                WebkitUserSelect: 'none',
                MozUserSelect: 'none',
                KhtmlUserSelect: 'none',
                msUserSelect:'none'
              }}
            >
              {">"}
            </Box>
          </Box>
        }
        </React.Fragment>
        :
        null
      }
    </React.Fragment>
  );
};

ButtonGroup.propTypes = {
    next: PropTypes.any,
    previous: PropTypes.any,
    res: PropTypes.any,
    typeCard: PropTypes.any
}




const CarouselSimple = ({deviceType,content,width,type,query}) => {

  const responsive = {
    big:{
      breakpoint: { max: 4000, min: 2880},
      items: 5,
      paritialVisibilityGutter: 2
    },
    desktop: {
      breakpoint: { max: 2879, min: 900 },
      items: 3,
      paritialVisibilityGutter:10
    },
    tablet: {
      breakpoint: { max: 899, min: 600 },
      items: 2,
      paritialVisibilityGutter: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1,
      paritialVisibilityGutter: -1
    }
  };

  const [ images, setImages ] = React.useState([]);


  const init = async ()=>{
    if(deviceType=='mobile' || deviceType =='tablet'){
      if(content && content.length > 3 && type !== 'categories') {
        setImages(content.slice(0,3));
      }else{
        setImages(content);
      }
    }else{
      if(content && content.length > 7 && type !== 'categories') {
        setImages(content.slice(0,7));
      }else{
        setImages(content);
      }
    }
  }


  React.useEffect(() => {
    init();
  },[content,deviceType]);



  
  return (
    <React.Fragment>
      <Box
        sx={{
          //padding:{xs:'0px 0px',sm:'0px 0px',md:'0px 20px'},
          position:'relative'
        }}
      >
        <Box
          component={Carousel}
          ssr={false}
          partialVisible
          deviceType={deviceType}
          responsive={responsive}
          arrows={false}
          showDots={false}
          renderButtonGroupOutside={true}
          customButtonGroup={<ButtonGroup typeCard={type} />}
        >

            {
              images.slice(0,images.length).map((item,index)=>{
                return (
                  <React.Fragment key={index}>
                    {
                      type === 'categories' ?
                      <React.Fragment>
                        <CardCategories item={item} width={width} index={index}  />
                      </React.Fragment>
                      :
                      <React.Fragment>
                        {
                          index < 6 ?
                          <React.Fragment>
                            {
                              type === 'auction' &&
                              <CardAuction item={item} width={width} index={index} isYour={false} />
                            }
                            {
                              type === "nft" &&
                              <CardNFT item={item} width={width} index={index} />
                            }
                            {
                              type === 'profile' &&
                              <CardProfile item={item} width={width} index={index} />
                            }
                            {
                              type === 'curators' &&
                              <CardCurators item={item} width={width} index={index} />
                            }
                            {
                              type === 'collections' &&
                              <CardCollection item={item} width={width} index={index} />
                            }
                          </React.Fragment>
                          :
                          type !== 'categories' && (deviceType!='mobile' || deviceType !='tablet') && 
                          <CardCollageSixImg images={images} width={width} type={type} query={query} />
                        }
                      </React.Fragment>
                    }
                  </React.Fragment>
                )
              })
            }

        </Box>
      </Box>
    </React.Fragment>
  );
};


CarouselSimple.propTypes = {
    deviceType: PropTypes.string,
    content: PropTypes.array,
    width: PropTypes.number,
    type: PropTypes.string,
    query: PropTypes.string
}

export default CarouselSimple;