import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import Collapse from  '@mui/material/Collapse';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';
import { useTranslation } from 'react-i18next';
import Checkbox from  '@mui/material/Checkbox';

const styleBox = {
    gap:'1rem',
    marginBottom:'0.5rem',
    border: '1px solid #e0e0e0',
    borderRadius:'8px',
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 1rem',
    '&:hover':{
        border: '1px solid #000',
    }
}

const FilterAvailability = ({
    requestIsLoad,
    requestIsLoadOwner,
    handleClickStatus,
    isBuyNow,
    isAuction,
    }) => {
    const { t } = useTranslation("translate");
    const [Availability, setAvailability] = useState(false);

    return (
        <Box
            component='article'
            display='flex'
            flexDirection='column'
            sx={{
                borderBottom:'1px solid #e0e0e0',
            }}   
        >
            <Box
                component='article'
                display='flex'
                justifyContent='space-between'
                alignItems='center'  
            >
                <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.availability")}</h1>
                <span onClick={()=>setAvailability(!Availability)}>{!Availability ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}</span>
            </Box>
            <Collapse in={Availability} timeout="auto" unmountOnExit>
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styleBox}
                    style={{
                        backgroundColor: requestIsLoad || requestIsLoadOwner ? '#E0E0E0':'transparent'
                    }}
                    onClick={()=> !requestIsLoad && !requestIsLoadOwner && handleClickStatus(!isBuyNow,'BUY_NOW')}
                >
                    <Box 
                        display='flex' 
                        alignItems='center'
                        sx={{
                            gap:'0.5rem',
                        }}>
                            <Checkbox 
                                disabled={requestIsLoad || requestIsLoadOwner}
                                checked={isBuyNow}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.buy_now")}</h1>
                    </Box>
                </Box>
                
                <Box
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'
                    sx={styleBox}
                    style={{
                        backgroundColor: requestIsLoad || requestIsLoadOwner ? '#E0E0E0':'transparent'
                    }}
                    onClick={()=>!requestIsLoad && !requestIsLoadOwner && handleClickStatus(!isAuction,'LIVE_AUCTION')}
                >
                    <Box 
                        display='flex' 
                        alignItems='center'
                        sx={{
                            gap:'0.5rem'
                        }}>
                            <Checkbox 
                                disabled={requestIsLoad || requestIsLoadOwner}
                                checked={isAuction}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                        <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.live_auction")}</h1>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

FilterAvailability.propTypes = {
    requestIsLoad: PropTypes.bool,
    requestIsLoadOwner: PropTypes.bool,
    handleClickStatus: PropTypes.func,
    isBuyNow: PropTypes.bool,
    setIsBuyNow: PropTypes.func,
    isAuction: PropTypes.bool,
    setIsAuction: PropTypes.func
};

export default FilterAvailability;