import React, { useState } from 'react'
import { Box, Checkbox, Collapse } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import RemoveIcon from '@mui/icons-material/Remove';

const styleBox = {
    gap:'1rem',
    marginBottom:'0.5rem',
    border: '1px solid #e0e0e0',
    borderRadius:'8px',
    boxSizing:'border-box',
    cursor:'pointer',
    padding:'0px 1rem',
    '&:hover':{
        border: '1px solid #000',
    }
 }

 const SortCollection = ({urlProject, wallet}) => {
    const { t } = useTranslation("translate");
    const [type, setType] = useState(false)
 
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [allTypes, setAllTypes] = useState(false)
    

    const handleClickType= (value) => {
        if(value == 'ONLY_VIDEO'){
            setIsImage(false)
            setIsVideo(true)
            setAllTypes(false)
        }
        if(value == 'ONLY_IMAGE'){
            setIsImage(true)
            setIsVideo(false)
            setAllTypes(false)
        }
        if(value == 'SEARCH'){
            setIsImage(false)
            setIsVideo(false)
            setAllTypes(true)
            ///project?domain=artcrypted.com&search=USER&wallet=0x016b76c25B7f2fF83A55fCbBf4fDAd842Bae9014&page=0&limit=
            urlProject(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&search=USER&wallet=${wallet}&page=0&limit=12`)
            return;
        }
        urlProject(`${process.env.REACT_APP_URL_API}/project?domain=${process.env.REACT_APP_DOMAIN}&search=USER&wallet=${wallet}&page=0&limit=12`)
    }

 

    return (
        <Box 
            sx={{
                position:'sticky',
                width:{xs:'100%',sm:'320px'},
                top: 0,
                pl:'15px',
                '@media screen and (max-width: 768px)':{
                    position:'block',
                }
            }}
        >
            <Box
                component='article'
                display='flex'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'  
                >
                    <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.type")}</h1>
                    <span onClick={()=>setType(!type)}>{!type ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}</span>
                </Box>
                <Collapse in={type} timeout="auto" unmountOnExit>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                onChange={()=>{ handleClickType('ONLY_IMAGE')}}
                                defaultChecked={isImage}
                                checked={isImage}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1>{t("sidebar_component.image")}</h1>
                        </Box>
{/*                         <Box>
                            156,563
                        </Box> */}
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                onChange={()=>{ handleClickType('ONLY_VIDEO')}}
                                defaultChecked={isVideo}
                                checked={isVideo}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1>{t("sidebar_component.video")}</h1>
                        </Box>
                    </Box>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={styleBox}
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                            }}>
                            <Checkbox 
                                onChange={()=>{ handleClickType('SEARCH')}}
                                defaultChecked={false}
                                checked={allTypes}
                                sx={{
                                    '&.Mui-checked':{
                                        color:'#1B2635'
                                    }
                                }}
                            />
                            <h1>All</h1>
                        </Box>
                    </Box>
                </Collapse>
            </Box>

        </Box>
    )
}
SortCollection.defaultProps = {
    urlProject: ()=>{},
    wallet: '',
}

SortCollection.propTypes = {
    urlProject: PropTypes.func,
    wallet: PropTypes.string,
}
export default SortCollection
