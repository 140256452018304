import sanityClient from 'sanityClient';


export const presentationHome = ()=>{
    return sanityClient.fetch(`*[_type == "presentationHome"] {
        desktop,
        tablet,
        mobile
    }`).then((data) => data)
}

export const topBar = ()=>{
    return sanityClient.fetch(`*[_type == "topBar"] {
        menu,
    }`).then((data) => data)
}

export const footer = ()=>{
    return sanityClient.fetch(`*[_type == "footer"] {
        marketplaceOptions,
        legalOptions,
        socialIcon []{
            social {
                asset->{
                    _id,
                    url
                } ,
            },
            isLink,
            externalLink,
            link
        }
    }`).then((data) => data)
}

export const aboutSanity = ()=>{
    return sanityClient.fetch(`*[_type == "about"] {
        header,
        headerImage {
            asset->{
                _id,
                url
            } ,
        },
        blockBody [] {
            blockTitle,
            block
        },
        bodyImage {
            asset->{
                _id,
                url
            } ,
        },
        blockTitle,
        links [] {
            item,
            isLink,
            externalLink,
            link
        }
    }`).then((data) => data)
}

export const sanityFAQS = ()=>{
    return sanityClient.fetch(`*[_type == "faqs"] {
        title,
        subtitle,
        list []{
            question,
            answer
        }
    }`).then((data) => data)
}

export const sanityRoadmap = ()=>{
    return sanityClient.fetch(`*[_type == "roadmap"] {
        title,
        headerImage {
            asset->{
                _id,
                url
            } ,
        },
        block []{
            year,
            lapse,
            titleDescription,
            description
        }
    }`).then((data) => data)
}

export const sanityTermsAndConditions = ()=>{
    return sanityClient.fetch(`*[_type == "termsAndConditions"] {
        header,
        body [] {
            title,
            body 
        }
    }`).then((data) => data)
}

export const sanityPrivacyPolicy = ()=>{
    return sanityClient.fetch(`*[_type == "privacyPolicy"] {
        title,
        body [] {
            title,
            body 
        }
    }`).then((data) => data)
}

export const sanityBurnParagraph = ()=>{
    return sanityClient.fetch(`*[_type == "burnNFT"] {
        paragraph
    }`).then((data) => data)
}


export const collectionWithNFTPay = ()=>{
    return sanityClient.fetch(`*[_type == "activateCardPayment"] {
        collectionsList
    }`).then((data) => data)
}

