import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from 'hooks/WalletContext';
import ButtonStyled from 'components/ButtonStyled';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';

const Buttons = ({nft, setOpenModalTransfer, setOpenModalSelectconfiguration, setOpenModalBurnNFT}) => {
    const {data} = useContext(Context);
    const { t } = useTranslation("translate");
    const [blockAllButtons, setBlockAllButtons] = useState(false);
    const location = useNavigate();

    useEffect(() => {
        //console.log('Component buttons', nft.owner, data.userAccount)
        if(data && data.userAccount){
            //console.log('Component buttons', nft.owner == data.userAccount)
            if(toString(nft.user.main_key).toUpperCase() == toString(data.userAccount).toUpperCase()) {
                console.log('Component buttons is true', nft.owner == data.userAccount)
                setBlockAllButtons(true)
            }else{
                setBlockAllButtons(false)
            }
        }
    } ,[data])

    
    const handleEditNFT = () => {
        if(data && data.userAccount && data.userAccount != 'undefined'){
            location(`/edit-nft?token_id=${nft.token_id}&address=${nft.project_key}`)
        }
    }

    return (
        <>
        {
            data && data.userAccount && data.userAccount != 'undefined' && nft && (nft.owner+'').toUpperCase() == (data.userAccount+'').toUpperCase() &&
            <React.Fragment>
                    <ButtonStyled
                        isDisabled={!blockAllButtons}
                        onClick={()=>{setOpenModalTransfer(true)}}
                        text={t("nft-screen.transfer_btn")}
                    />
                    <ButtonStyled
                        onClick={()=>setOpenModalSelectconfiguration(true)}
                        text={t("nft-screen.settings_btn")}
                    />
                    <ButtonStyled
                        isDisabled = {false}
                        onClick={handleEditNFT}
                        text={t("nft-screen.edit_btn")}
                    />
                    <ButtonStyled
                        style={{display: 'none'}}
                        isDisabled = {true}
                        onClick={()=>{setOpenModalBurnNFT(true)}}
                        text={t("nft-screen.burn_btn")}
                    />
                    <Box display="none">
                        <ButtonStyled
                            isDisabled = {true}
                            text={t("nft-screen.view_my_offers_btn")}
                        />
                    </Box>
            </React.Fragment>
        }
        </>
    )
}

Buttons.propTypes = {
    nft: PropTypes.object.isRequired,
    setOpenModalTransfer: PropTypes.func,
    setOpenModalSelectconfiguration: PropTypes.func,
    setOpenModalBurnNFT: PropTypes.func
}

export default Buttons;