import React, { useState } from 'react'
import Box from  '@mui/material/Box';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Collapse from  '@mui/material/Collapse';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ListAutoComplete from 'components/ListAutoComplete';

const FilterCategory = ({requestIsLoad,categoriesString,setCategoriesString,handleChangeCategories}) => {
    const { t } = useTranslation("translate");
    const [categories, setCategories] = useState(false)
    return (
        <Box
                component='article'
                display='flex'
                flexDirection='column'
                sx={{
                    borderBottom:'1px solid #e0e0e0',
                }}   
            >
                <Box
                    component='article'
                    display='flex'
                    justifyContent='space-between'
                    alignItems='center'  
                >
                    <h1 style={{ fontFamily:'Futura,Trebuchet MS,Arial,sans-serif '}}>{t("sidebar_component.category")}</h1>
                    <span onClick={()=>setCategories(!categories)}>{!categories ? <AddIcon sx={{cursor:'pointer'}} /> : <RemoveIcon  sx={{cursor:'pointer'}}/>}</span>
                </Box>
                <Collapse in={categories} timeout="auto" unmountOnExit>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        sx={{
                            gap:'1rem',
                            marginBottom:'0.5rem',
                            borderRadius:'8px',
                            boxSizing:'border-box',
                            cursor:'pointer',
                            padding:'0px 1rem'
                        }}
                        width='100%'
                    >
                        <Box 
                            display='flex' 
                            alignItems='center'
                            sx={{
                                gap:'0.5rem',
                                width:'100%',
                            }}>
                            <ListAutoComplete width={"100%"} categories={categoriesString} setCategories={setCategoriesString} requestIsLoad={requestIsLoad} onFunction={handleChangeCategories} />
                        </Box>
                    </Box>

                </Collapse>
            </Box>
    );
};

FilterCategory.propTypes = {
    requestIsLoad: PropTypes.bool,
    categoriesString: PropTypes.string,
    setCategoriesString: PropTypes.func,
    handleChangeCategories: PropTypes.func
};

export default FilterCategory;