import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { sign } from 'services/MassiveMint/utils/signature';
import { deploy } from 'services/MassiveMint/ERC721/deploy';
import { initialize } from 'services/MassiveMint/ERC721/initialize';
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { updateProjectKey } from 'services//MassiveMint/project/updateProjectKey';
//import {PreMintMassive}  from 'massive-mint';
import PreMintMassive from './PreMintMassive/PreMintMassive';
import { Context } from 'hooks/WalletContext';


const MassiveMint = () => {
    const { t } = useTranslation("translate");
    const {search} = useLocation();
    const history = useNavigate();
    const query = new URLSearchParams(search);
    const address = query.get('address');
    const {data} = useContext(Context);
    let day = 86399000; // one day
    let nMonth = day * 30; // 30 days
    let maxDate = (new Date().getTime()) + (nMonth*3); // max date selected

    let urlCollection = `${process.env.REACT_APP_URL_API}/project?address=${address}&domain=${process.env.REACT_APP_DOMAIN}`;

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const style = {
        border: '1px solid #8742D9',
        color:'#000',
        buttonRadio:{
            textColor:'#000',
            buttonColor:'#8742D9'
        }
    }


    const handleRoute = (route) => {
        history(route)
    }

    return (
        <Box
            sx={{
                background: '#fff',
                minHeight: '100vh'
            }}
        >
            <PreMintMassive
                data={data}
                titleCollection={t('pre_mint_nft_massive.collection_selected')}
                urlCollections={urlCollection}
                t={t}
                sign={sign}
                deploy={deploy}
                updateProjectKey={updateProjectKey}
                initialize={initialize}
                addressCollection={address}
                domain={process.env.REACT_APP_DOMAIN}
                blockchainName={process.env.REACT_APP_NETWORK_NAME}
                api={process.env.REACT_APP_URL_API}
                handleRoute={handleRoute}
                maxDate={maxDate}
                style={style}
            />
        </Box>
    );
};

export default MassiveMint;
